/* eslint react/prop-types: off */
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Row, Col, Card, CardBody } from "reactstrap"
import moment from "moment"

import { ReactTableView } from "../../ReactTableView"
import { Pagination } from "../../Pagination"

import * as HOC from "../../../HOC"
const TableDataLoading = HOC.withTableDataLoading()

export const HistoryTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns, setColumns] = useState([
    {
      Header: t("date"),
      accessor: "created_at",
      id: "created_at",
      Cell: ({ value }) => {
        return value ? moment(value).format("DD/MM/YYYY HH:mm") : "-"
      },
    },
    {
      Header: t("editor"),
      accessor: "name",
      id: "name",
      Cell: ({ row: { original }, value }) => original.user?.name,
    },
    {
      Header: t("title"),
      accessor: "title",
      id: "title",
      Cell: ({ row: { original }, value }) => value,
    },
  ])

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody className={"bg-neutral"}>
            <TableDataLoading
              isLoading={props.loading}
              isFailed={props.error}
              error={props.error}
            >
              <ReactTableView
                isLoading={props.loading}
                columns={columns}
                data={data}
              />

              <Pagination
                data={props.meta}
                onPageChange={props.onPageChange}
                className={"mt-2"}
              />
            </TableDataLoading>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

HistoryTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,

  onPageChange: PropTypes.func,
}
