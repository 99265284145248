import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import {
  selectUserAccountRemove,
  selectUserAccounts,
  userAccountRemove,
  userAccountsList,
} from "store"
import { ConfirmPopup, PageHeader, UserAccountsTable } from "components"
import { useDeepCompareEffect, useFirstMountState } from "react-use"
import { TableFilter } from "./TableFilter"
import { useConfirmModal } from "../../../../hooks"
import { UserGenerate } from "../UserGenerate"

const UserTableContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const isFirstMount = useFirstMountState()

  useEffect(() => {
    fetchData()
    return () => {
      dispatch(userAccountsList.cleanState())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      fetchData()
    }
  }, [props.filters.values, props.sort, props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 10,
      sort: props.sort.length ? props.sort : null,
    }
    const { keywords, role } = props.filters.values

    params.filters = {
      keywords: keywords !== "" ? keywords : null,
      role: role !== "" ? role : null,
      search_fields: ["name", "email"],
    }

    dispatch(userAccountsList.getList({ params }))
  }

  const handleSearch = value => {
    dispatch(userAccountsList.changeFilter({ keywords: value }))
  }
  const handleSort = useCallback(value => {
    dispatch(userAccountsList.changeSort({ sort: value }))
  }, [])

  const handlePageChange = item => {
    dispatch(userAccountsList.setPage(item.selected + 1))
  }

  const onRemoveSuccess = () => {
    fetchData()
  }

  const { showModal, handleConfirm, modalRef, closeModal } = useConfirmModal(
    item => {
      dispatch(
        userAccountRemove.remove({
          id: item.id,
          onSuccess: onRemoveSuccess,
        })
      )
    },
    () => {
      dispatch(userAccountRemove.cleanState())
    }
  )

  return (
    <>
      <PageHeader
        title={t("title.users")}
        headerRight={
          <div className={"d-flex"}>
            <UserGenerate onSuccess={fetchData} />
          </div>
        }
      />

      <TableFilter onSearch={handleSearch} />
      <div className={"mb-2"} />
      <UserAccountsTable
        error={props.error}
        page={props.page}
        loading={props.loading}
        meta={props.meta}
        onPageChange={handlePageChange}
        entities={props.entities}
        onSortChange={handleSort}
        sortValue={props.sort}
        onRemove={showModal}
        removingLoading={props.removingLoading}
      />
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      />
    </>
  )
}

UserTableContainerComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  meta: PropTypes.object,
  entities: PropTypes.array,
  page: PropTypes.number,
  filters: PropTypes.object,
  sort: PropTypes.array,
  removingLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  const userAccountsData = selectUserAccounts(state)
  const { loading: removingLoading } = selectUserAccountRemove(state)
  return {
    ...userAccountsData,
    removingLoading,
  }
}

export const UserTableContainer = connect(mapStateToProps)(
  UserTableContainerComponent
)
