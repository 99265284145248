import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { staticMeasurementAll } from "./slice"

export function* getData({ payload: { params } }) {
  try {
    const response = yield call(API.common.getMeasurementsOptions, params)

    const { data } = response

    yield put(staticMeasurementAll.getDataSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(staticMeasurementAll.getDataError(message))
  }
}

export function* watchGetData() {
  yield takeEvery(staticMeasurementAll.getData, getData)
}

function* allSaga() {
  yield all([fork(watchGetData)])
}

export default allSaga
