import React from "react"
import { Redirect } from "react-router-dom"

import {
  DashboardIndex,
  Login,
  Profile,
  UserIdeasIndex,
  UsersIndex,
  PagesIndex,
  TranslationIndex,
} from "../pages"

const publicRoutes = [{ path: "/login", component: Login }]

const adminRoutes = [
  {
    path: "/dashboard",
    component: DashboardIndex,
  },
  { path: "/profile", component: Profile, middlewares: [] },
  { path: "/user-ideas", component: UserIdeasIndex, middlewares: [] },
  { path: "/users", component: UsersIndex },
  { path: "/pages", component: PagesIndex },
  { path: "/translations", component: TranslationIndex },
  // eslint-disable-next-line react/display-name
  { path: "*", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const utilityRoutes = []

export { publicRoutes, adminRoutes, utilityRoutes }
