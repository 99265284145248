import { SET_AUTH_DATA, CLEAN_AUTH_DATA } from "./actionTypes"

const initialState = {
  isLoggedIn: false,
  sessionCode: null,
}

const data = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_DATA:
      state = {
        ...state,
        ...action.payload,
      }
      break

    case CLEAN_AUTH_DATA:
      state = {
        ...state,
        ...initialState,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default data
