import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { translationUpdate } from "./slice"
import { API } from "api"
import { translationList } from "../list/slice"

function* update({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.translation.updateTranslation, id, params)
    const { data } = response
    if (onSuccess) {
      onSuccess()
    }
    toastr.success(i18n.t("saved_success"))

    yield put(translationUpdate.updateCompleted())
    yield put(translationList.updateListItem({ ...data.data }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      translationUpdate.updateRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdate() {
  yield takeEvery(translationUpdate.update, update)
}

function* updateSaga() {
  yield all([fork(watchUpdate)])
}

export default updateSaga
