import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import i18n from "../../../i18n"
import { userIdeasList } from "./slice"
import { API } from "api"
import { RequestUtils } from "utils"

export function* getList({ payload: { params } }) {
  try {
    let requestParams = {
      includes: [],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(API.admin.getUserIdeas, requestParams)

    const { data } = response

    yield put(userIdeasList.getListCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(userIdeasList.getListRejected(message))
  }
}

export function* watchGetList() {
  yield takeLatest(userIdeasList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
