import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import { TermsAndConditionsForm } from "./TermsAndConditionsForm"
import { useSubmitHandler } from "hooks"
import { selectStaticPageByAlias, updateStaticPage } from "store"
import { TERMS_AND_CONDITIONS } from "consts"
import { EditorUtils } from "utils"

const TermsAndConditionsContainerComponent = ({ loading, error }) => {
  const data = useSelector(state =>
    selectStaticPageByAlias(state, TERMS_AND_CONDITIONS)
  )

  const dispatch = useDispatch()

  const { handleSubmit } = useSubmitHandler(
    ({ params, onError }) => {
      dispatch(
        updateStaticPage.update({
          id: data.id,
          params: {
            content: params.content,
            title: data.title,
            alias: data.alias,
          },
          onError,
        })
      )
    },
    () => updateStaticPage.cleanState()
  )

  return (
    <TermsAndConditionsForm
      initialValues={{
        content: data.content
          ? EditorUtils.draftJs.getEditorState(data.content)
          : undefined,
      }}
      loading={loading}
      error={error}
      onSubmit={handleSubmit}
    />
  )
}

TermsAndConditionsContainerComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
}

const mapStateToProps = state => {
  const { loading, error } = state.staticPages.update
  return {
    loading,
    error,
  }
}

export const TermsAndConditionsContainer = connect(mapStateToProps)(
  TermsAndConditionsContainerComponent
)
