import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import i18n from "../../../i18n"
import { API } from "api"
import { userProfileData } from "store/user/actions"
import { fetchUserData } from "./slice"
import { ROLE_ADMIN } from "../../../consts"
import { logoutUser } from "../../auth/logout/actions"
import toastr from "toastr"

export function* fetchUser({ payload: { history } }) {
  try {
    const includes = ["company", "department", "settings"]

    const response = yield call(API.profile.getProfile, { includes })

    const { data } = response
    const { role } = data.data
    if (role !== ROLE_ADMIN) {
      yield put(logoutUser({ history }))
      toastr.error(i18n.t("access.only-for-admin"))
      return
    }
    yield put(fetchUserData.fetchCompleted())

    yield put(userProfileData.setData({ ...data?.data }))
  } catch (error) {
    const message = error.response?.data?.message
      ? error.response.data.message
      : i18n.t("error")
    yield put(fetchUserData.fetchRejected(message))
  }
}

export function* watchUserFetch() {
  yield takeEvery(fetchUserData.fetch, fetchUser)
}

function* loadSaga() {
  yield all([fork(watchUserFetch)])
}

export default loadSaga
