import { takeEvery, fork, put, all, select } from "redux-saga/effects"

import i18n from "i18n"
import { socketOnUserLogin } from "./actions"

import { logoutUser } from "../../../auth/logout/actions"

export const authDataState = state => state.auth.data

function* onLogin({ payload: { data, history } }) {
  const { logout, secret } = data

  const { sessionCode } = yield select(authDataState)

  if (logout && secret !== sessionCode) {
    const onSuccess = () => {
      history.replace({
        ...history.location,
        search: `?message=${i18n.t(
          "user-auth-session-over"
        )}&messageType=warning`,
      })
    }

    yield put(logoutUser({ onSuccess }))
  }
}

export function* watchOnLogin() {
  yield takeEvery(socketOnUserLogin, onLogin)
}

function* loginSaga() {
  yield all([fork(watchOnLogin)])
}

export default loginSaga
