import React from "react"
import PropTypes from "prop-types"
import { Alert, Spinner } from "reactstrap"
import classNames from "classnames"
import i18n from "../i18n"

export default () => {
  const anon = ({
    isLoading,
    isFailed = false,
    error,
    children,
    spinnerSize = "lg",
    customLoaderClass = null,
    errorContainerClass = null,
    center = true,
    ...rest
  }) =>
    isLoading ? (
      <div
        className={classNames({
          "data-loader": true,
          "text-center": center,
          [customLoaderClass]: customLoaderClass,
        })}
      >
        <Spinner size={spinnerSize} color="primary" />
      </div>
    ) : !isLoading && !isFailed ? (
      <>{children}</>
    ) : (
      <div className={errorContainerClass}>
        <Alert color={"danger"}>{error || i18n.t("error")}</Alert>
      </div>
    )

  return anon
}
