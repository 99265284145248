import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"

import { Header } from "./Topbar"
import { PanelTopBarProvider, PanelSidebarProvider } from "contexts"

export const PagesLayout = props => {
  return (
    <PanelSidebarProvider>
      <PanelTopBarProvider>
        <React.Fragment>
          <div className={"page-content"}>
            <Container fluid>
              <Header />

              <div>{props.children}</div>
            </Container>
          </div>
        </React.Fragment>
      </PanelTopBarProvider>
    </PanelSidebarProvider>
  )
}

PagesLayout.propTypes = {
  children: PropTypes.any,
  topBarOptions: PropTypes.object,
}
