import React from "react"
import PropTypes from "prop-types"
import { Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { Button, ControllerHF, InputField } from "components"
import { validationSchema } from "./validation"
import { langHelper } from "helpers/lang"

export const TranslationForm = props => {
  const { t } = useTranslation()

  const langs = langHelper.general.getLanguages().reverse()

  const defaultValue = langs.reduce((acc, cur) => {
    const { code } = cur

    return (acc[code] = ""), acc
  }, {})

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      key: "",
      value: {
        ...defaultValue,
      },
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema),

    mode: "onChange",
  })

  const onSubmit = values => {
    props.onSubmit(values, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <ControllerHF
          name={"key"}
          control={control}
          component={InputField}
          id={"key"}
          label={t("key")}
          placeholder={t("key")}
        />

        {langs.map(({ code, title }) => (
          <ControllerHF
            key={code}
            name={`value.${code}`}
            id={`value.${code}`}
            control={control}
            component={InputField}
            label={title}
            placeholder={title}
            type={"textarea"}
            rows={2}
          />
        ))}

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

TranslationForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
