import axios from "axios"

class AuthAPI {
  signIn = async params => {
    try {
      await axios.get("sanctum/csrf-cookie")

      return axios.post("api/auth/login", params)
    } catch (e) {
      throw e
    }
  }

  logOut = () => {
    return axios.post("api/auth/logout")
  }

  signUp = async params => {
    try {
      await axios.get("sanctum/csrf-cookie")

      return axios.post("api/auth/register", params)
    } catch (e) {
      throw e
    }
  }

  forgotPassword = async params => {
    try {
      await axios.get("sanctum/csrf-cookie")

      return axios.post("api/auth/forgot-password", params)
    } catch (e) {
      throw e
    }
  }

  resetPassword = async params => {
    try {
      await axios.get("sanctum/csrf-cookie")

      return axios.post("api/auth/reset-password", params)
    } catch (e) {
      throw e
    }
  }

  sendVerifyEmail = async params => {
    try {
      await axios.get("sanctum/csrf-cookie")

      return axios.post("api/auth/email/verification-notification", params)
    } catch (e) {
      throw e
    }
  }
}

export const auth = new AuthAPI()
