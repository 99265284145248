import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const translationCreateSlice = createSlice({
  name: "translationCreate",
  initialState,
  reducers: {
    create(state) {
      state.loading = true
      state.error = null
    },
    createCompleted(state) {
      state.loading = false
    },
    createRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} create
 * @property {function} createCompleted
 * @property {function} createRejected
 * @property {function} cleanState
 */
export const translationCreate = translationCreateSlice.actions

export default translationCreateSlice.reducer
