import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"

import { Translations } from "./Translations"

export const TranslationIndex = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Translations />
      </Route>
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
