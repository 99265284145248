import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Pagination } from "../../Pagination"
import { MasonryGrid } from "../../Masonry"

import * as HOC from "../../../HOC"

const DataShower = HOC.withDataShowerOverlay()

export const MasonryDataGrid = ({
  masonryConfig,
  loading,
  error,
  items = [],
  renderItem,
  meta,
  onPageChange,
  empty,
  className = "",
  masonryRef = null,
}) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <DataShower isLoading={loading} isFailed={error} error={error}>
        {!!items.length ? (
          <>
            <MasonryGrid
              masonryRef={masonryRef}
              items={items}
              renderItem={renderItem}
              {...masonryConfig}
            />

            <Pagination
              data={meta}
              onPageChange={onPageChange}
              className={"mt-4"}
            />
          </>
        ) : !loading ? (
          empty ? (
            empty
          ) : (
            t("empty")
          )
        ) : null}
      </DataShower>
    </div>
  )
}

MasonryDataGrid.propTypes = {
  masonryRef: PropTypes.any,
  masonryConfig: PropTypes.object,
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  renderItem: PropTypes.func,
  empty: PropTypes.any,
  onPageChange: PropTypes.func,
  className: PropTypes.string,
}
