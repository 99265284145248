import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Button } from "components"
import { useModal } from "hooks"
import { TranslationCreate } from "../TranslationCreate"

export const Header = ({ onCreateSuccess }) => {
  const { t } = useTranslation()

  const { modalRef, closeModal, showModal } = useModal()

  const onSuccess = () => {
    onCreateSuccess()
  }

  return (
    <div className={"d-flex"}>
      <Button
        color="secondary"
        leftIcon={"bx bx-plus"}
        title={"add"}
        onClick={showModal}
      />

      <TranslationCreate
        modalRef={modalRef}
        onClose={closeModal}
        onSuccess={onSuccess}
      />
    </div>
  )
}

Header.propTypes = {
  onCreateSuccess: PropTypes.func,
}
