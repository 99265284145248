import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { updateStaticPage } from "./slice"
import { staticPagesList } from "../list/slice"

function* updateItem({ payload: { id, params, onError } }) {
  try {
    const response = yield call(API.admin.updateStaticPage, id, params)

    const { data } = response

    toastr.success(i18n.t("page-content.edit.success"))

    yield put(updateStaticPage.updateCompleted())
    yield put(staticPagesList.updateItem({ id, ...params }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      updateStaticPage.updateRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateItem() {
  yield takeEvery(updateStaticPage.update, updateItem)
}

function* updateSaga() {
  yield all([fork(watchUpdateItem)])
}

export default updateSaga
