import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGIN_USER_CLEAN_STATE,
  LOGIN_SEND_VERIFY_EMAIL,
} from "./actionTypes"

const initialState = {
  loading: false,
  loginError: null,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        loginError: null,
      }
      break

    case LOGIN_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case LOGIN_USER_ERROR:
      state = {
        ...state,
        loading: false,
        loginError: action.payload,
      }
      break

    case LOGIN_USER_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break

    case LOGIN_SEND_VERIFY_EMAIL:
      state = {
        ...state,
        loginError: null,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default login
