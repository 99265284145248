import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { TranslationForm } from "../../TranslationForm"
import { ValidationsUtils } from "utils"
import { translationCreate } from "store"

const TranslationCreateContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const onDestroy = () => {
    dispatch(translationCreate.cleanState())
  }

  useEffect(() => {
    return () => {
      onDestroy()
    }
  }, [])

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onSuccess()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      translationCreate.create({
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  return (
    <TranslationForm
      initialValues={{}}
      loading={props.loading}
      error={props.error}
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
    />
  )
}

TranslationCreateContainerComponent.propTypes = {
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onSuccess: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.translation.create
  return {
    loading,
    error,
  }
}

export const TranslationCreateContainer = connect(mapStateToProps)(
  TranslationCreateContainerComponent
)
