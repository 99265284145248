import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Modal } from "components"
import { TranslationUpdateContainer } from "./TranslationUpdateContainer"

export const TranslationUpdate = ({ modalRef, onClose, onSuccess, data }) => {
  const { t } = useTranslation()
  return (
    <Modal ref={modalRef} title={t("edit")}>
      <TranslationUpdateContainer
        data={data}
        onCancel={onClose}
        onSuccess={onSuccess}
      />
    </Modal>
  )
}

TranslationUpdate.propTypes = {
  modalRef: PropTypes.any,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  data: PropTypes.object,
}
