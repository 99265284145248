import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import loginReducer from "./login/reducer"
import dataReducer from "./data/reducer"
import logoutReducer from "./logout/reducer"

const authDataPersistConfig = {
  key: "authData",
  storage,
}

const authReducer = combineReducers({
  login: loginReducer,
  data: persistReducer(authDataPersistConfig, dataReducer),
  logout: logoutReducer,
})

export default authReducer
