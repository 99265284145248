import React from "react"
import PropTypes from "prop-types"
import { Col, Container } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle, PageTitle } from "components"
import { ChangePassword } from "./components"

const ProfileComponent = props => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <MegaTagTitle title={"title.profile"} />

      <div className="page-content">
        <Container fluid>
          <PageTitle title={t("title.profile")} />
          <Col lg={"6"} className={"mb-4"}>
            <ChangePassword />
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProfileComponent.propTypes = {
  user: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    user: state.user.profile.data,
  }
}

export const Profile = connect(mapStateToProps)(ProfileComponent)
