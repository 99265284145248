import React from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"

export const CommonRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <Route
    render={props => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
    {...rest}
  />
)

CommonRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
}
