import React, { createContext, useContext, useState } from "react"
import PropTypes from "prop-types"

import { EchoClient } from "services"

const EchoContext = createContext({
  client: {},
  setClient: () => {},

  init: false,
  setInit: () => {},
})

const EchoProvider = ({ children }) => {
  const [client, setClient] = useState(EchoClient)
  const [init, setInit] = useState(false)

  return (
    <EchoContext.Provider value={{ client, init, setInit }}>
      {children}
    </EchoContext.Provider>
  )
}

EchoProvider.propTypes = {
  children: PropTypes.any,
}

const useEchoContext = () => useContext(EchoContext)

export { EchoProvider, useEchoContext }
