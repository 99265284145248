import { createSelector, createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,
  isFetched: false,
}

const staticPagesListSlice = createSlice({
  name: "staticPagesList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListCompleted(state, action) {
      state.listLoading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
      state.isFetched = true
    },
    getListRejected(state, action) {
      state.listLoading = false
      state.listError = action.payload
      state.isFetched = true
    },
    updateItem(state, action) {
      state.list = state.list.map(page => {
        if (page.id === action.payload.id) {
          return { ...page, ...action.payload }
        } else {
          return page
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})

export const selectStaticPageByAlias = createSelector(
  [state => state.staticPages.list.list, (_, pageAlias) => pageAlias],
  (pages, pageAlias) => pages.find(graph => graph.alias === pageAlias)
)

/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 *  @property {function} updateItem
 *  @property {function} cleanState
 */

export const staticPagesList = staticPagesListSlice.actions

export default staticPagesListSlice.reducer
