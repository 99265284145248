import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Modal } from "components"
import { TranslationCreateContainer } from "./TranslationCreateContainer"

export const TranslationCreate = ({ modalRef, onClose, onSuccess }) => {
  const { t } = useTranslation()

  return (
    <Modal ref={modalRef} title={t("add")}>
      <TranslationCreateContainer onCancel={onClose} onSuccess={onSuccess} />
    </Modal>
  )
}

TranslationCreate.propTypes = {
  modalRef: PropTypes.any,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}
