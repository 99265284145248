import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,
  filters: {
    values: {
      keywords: "",
    },
  },
}

const translationListSlice = createSlice({
  name: "translationList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListCompleted(state, action) {
      state.listLoading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListRejected(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    changeFilter(state, action) {
      state.page = 1
      state.filters.values = { ...state.filters.values, ...action.payload }
    },
    updateListItem(state, action) {
      state.list = state.list.map(item => {
        if (item.id === action.payload.id) {
          return action.payload
        } else {
          return item
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 * @property {function} updateListItem
 * @property {function} setPage
 * @property {function} changeFilter
 * @property {function} cleanState
 */
export const translationList = translationListSlice.actions

export default translationListSlice.reducer
