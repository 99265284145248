import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Table } from "reactstrap"
import { useSortBy, useTable } from "react-table"
import { useTranslation } from "react-i18next"

export const ReactTableView = ({
  columns,
  data,
  initialSort = [],
  disableSortBy = true,
  onSort,
  isLoading,
  initialState = {},
}) => {
  const { t } = useTranslation()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: initialSort,
        ...initialState,
      },
      defaultCanSort: false,
      disableSortBy: disableSortBy || data.length === 0,
      manualSortBy: true,
      disableMultiSort: true,
      autoResetSortBy: false,
    },
    useSortBy
  )

  useEffect(() => {
    if (onSort) {
      onSort(sortBy)
    }
  }, [onSort, sortBy])

  const getColumnStyle = column => {
    return { ...column.style }
  }

  return (
    <div className="table-responsive">
      <Table
        className={"table-border-bottom align-middle"}
        {...getTableProps()}
      >
        <thead className={"table-head-bg "}>
          {headerGroups.map((headerGroup, i) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={`header-group-${i}`}
            >
              {headerGroup.headers.map((column, j) => (
                <th
                  key={`column-${j}`}
                  {...column.getHeaderProps([
                    {
                      className: column.headerClassName,
                    },
                    column.getSortByToggleProps({ title: null }),
                  ])}
                  style={{
                    ...column.getHeaderProps(
                      column.getSortByToggleProps().style
                    ),
                    ...getColumnStyle(column),
                  }}
                >
                  <div className={"d-flex"}>
                    <div className={"d-flex flex-fill text-nowrap"}>
                      {column.render("Header")}
                      <div>
                        {column.canSort &&
                          (column.isSorted ? (
                            <i
                              className={
                                "bx font-size-16 align-middle" +
                                (column.isSortedDesc
                                  ? " bx-sort-down"
                                  : " bx-sort-up")
                              }
                            />
                          ) : (
                            <i
                              className="bx bx-sort align-middle"
                              style={{ color: "#adb5bd" }}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className={"bg-neutral"}>
          {(rows.length > 0 &&
            rows.map((row, ri) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={`row-${ri}`}>
                  {row.cells.map((cell, cj) => {
                    return (
                      <td key={`cell-${cj}`} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })) || (
            <tr>
              <td colSpan={columns.length}>
                {!isLoading ? (
                  t("empty")
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

ReactTableView.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  initialSort: PropTypes.array,
  disableSortBy: PropTypes.bool,
  onSort: PropTypes.func,
  initialState: PropTypes.object,

  isLoading: PropTypes.bool,
}
