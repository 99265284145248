import React from "react"
import PropTypes from "prop-types"

import { Logo } from "./Logo"

export const LogoIcon = props => {
  switch (props.type) {
    default:
      return <Logo {...props} />
  }
}

LogoIcon.propTypes = {
  type: PropTypes.string,
}
