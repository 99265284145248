import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useConfirmModal } from "hooks"
import { ConfirmPopup } from "components"
import {
  selectAllUserIdeas,
  selectUserIdeaRemove,
  selectUserIdeasList,
  userIdeaRemove,
  userIdeasList,
} from "store"
import { UserIdeasTable } from "./UserIdeasTable"

const UserIdeasContainerComponent = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()
    return () => {
      dispatch(userIdeasList.cleanState())
    }
  }, [props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
      sort: props.sort.length ? props.sort : null,
    }
    dispatch(userIdeasList.getList({ params }))
  }

  const handlePageChange = item => {
    dispatch(userIdeasList.setPage(item.selected + 1))
  }

  const onRemoveSuccess = () => {
    fetchData()
  }

  const { showModal, handleConfirm, modalRef, closeModal } = useConfirmModal(
    item => {
      dispatch(
        userIdeaRemove.remove({
          id: item.id,
          onSuccess: onRemoveSuccess,
        })
      )
    },
    () => {
      dispatch(userIdeaRemove.cleanState())
    }
  )

  return (
    <>
      <UserIdeasTable
        error={props.error}
        loading={props.loading}
        page={props.page}
        entities={props.entities}
        onPageChange={handlePageChange}
        meta={props.meta}
        onRemove={showModal}
        removingLoading={props.removingLoading}
      />
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      />
    </>
  )
}

UserIdeasContainerComponent.propTypes = {
  entities: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  removingLoading: PropTypes.bool,
  sort: PropTypes.array,
}

const mapStateToProps = state => {
  const userIdeasData = selectUserIdeasList(state)
  const entities = selectAllUserIdeas(state)
  const { loading: removingLoading } = selectUserIdeaRemove(state)
  return {
    ...userIdeasData,
    entities,
    removingLoading,
  }
}

export const UserIdeasContainer = connect(mapStateToProps)(
  UserIdeasContainerComponent
)
