import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect, withRouter } from "react-router-dom"
import { connect } from "react-redux"

const PublicRouteComponent = ({ component: Component, ...rest }) => (
  <Route
    render={props => {
      if (rest.isLoggedIn) {
        return (
          <Redirect
            to={{ pathname: `/dashboard`, state: { from: props.location } }}
          />
        )
      }
      return <Component {...props} />
    }}
    {...rest}
  />
)

PublicRouteComponent.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
  isLoggedIn: PropTypes.bool,
}

const mapStateToProps = state => {
  const { isLoggedIn } = state.auth.data
  return { isLoggedIn }
}

export const PublicRoute = withRouter(
  connect(mapStateToProps, {})(PublicRouteComponent)
)
