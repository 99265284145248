import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Alert, Card, CardBody, CardHeader, CardTitle, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import { Button, ControllerHF, PasswordInputFiled } from "components"
import { profilePasswordUpdate } from "store/actions"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

const passwordMinLength = 8

const ChangePasswordComponent = ({ loading, error }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, setError, handleSubmit, reset } = useForm({
    defaultValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        current_password: Yup.string()
          .min(passwordMinLength, "field.error.password.length")
          .required("field.error.required"),
        password: Yup.string()
          .min(passwordMinLength, "field.error.password.length")
          .required("field.error.required"),
        password_confirmation: Yup.string()
          .required("field.error.required")
          .oneOf([Yup.ref("password"), null], "field.error.password.no.match")
          .min(passwordMinLength, "field.error.password.length"),
      })
    ),
    mode: "onChange",
  })
  useEffect(() => {
    return () => {
      dispatch(profilePasswordUpdate.cleanState())
    }
  }, [])

  const onSubmit = values => {
    dispatch(
      profilePasswordUpdate.update({
        params: values,
        onSubmitProps: { reset, setError },
      })
    )
  }

  return (
    <Card className={"h-100"}>
      <CardHeader className={"bg-dark"}>
        <CardTitle className={"text-white mb-0"}>{t("password")}</CardTitle>
      </CardHeader>
      <CardBody className={"bg-neutral"}>
        {error && <Alert color="danger">{error.message}</Alert>}

        <form className={"form-horizontal"} onSubmit={handleSubmit(onSubmit)}>
          <Col lg={12}>
            <ControllerHF
              name="current_password"
              label={t("field.label.current-password")}
              placeholder={t("field.placeholder.current-password")}
              control={control}
              component={PasswordInputFiled}
              id={"current_password"}
              translateParams={{ count: passwordMinLength }}
            />
          </Col>

          <Col lg={12}>
            <ControllerHF
              name="password"
              label={t("field.label.new-password")}
              placeholder={t("field.placeholder.new-password")}
              control={control}
              component={PasswordInputFiled}
              id={"password"}
              translateParams={{ count: passwordMinLength }}
            />
          </Col>

          <Col lg={12}>
            <ControllerHF
              name="password_confirmation"
              label={t("field.label.confirm-new-password")}
              placeholder={t("field.placeholder.confirm-new-password")}
              id={"password_confirmation"}
              control={control}
              component={PasswordInputFiled}
              translateParams={{ count: passwordMinLength }}
            />
          </Col>

          <div className="mt-3">
            <Button
              submit
              title={"btn.change_password"}
              disabled={loading}
              loading={loading}
            />
          </div>
        </form>
      </CardBody>
    </Card>
  )
}

ChangePasswordComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.profile.password
  return {
    loading,
    error,
  }
}

export const ChangePassword = connect(mapStateToProps)(ChangePasswordComponent)
