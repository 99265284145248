import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { userAccountRemove } from "./slice"

function* remove({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(API.admin.deleteUser, id)

    const { data } = response

    yield put(userAccountRemove.removeCompleted())

    toastr.success(i18n.t("remove.success"))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      userAccountRemove.removeRejected({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(userAccountRemove.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
