import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { ConfirmPopup, IconButton } from "components"
import { useConfirmModal } from "hooks"
import { activateCompany, selectActivateCompany } from "store"
import { useTranslation } from "react-i18next"

export const ActivateCompany = ({ userId }) => {
  const { t } = useTranslation()
  const { loading } = useSelector(state => selectActivateCompany(state))
  const dispatch = useDispatch()

  const { showModal, handleConfirm, modalRef, closeModal } = useConfirmModal(
    () => {
      dispatch(
        activateCompany.activate({
          id: userId,
        })
      )
    },
    () => {
      dispatch(activateCompany.cleanState())
    }
  )

  return (
    <>
      <div className={"d-flex justify-content-end"}>
        <IconButton name={"undo"} bg onClick={showModal} disabled={loading} />
      </div>
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      >
        <p className={"font-size-22"}>{t("undo-company-delete")}</p>
      </ConfirmPopup>
    </>
  )
}

ActivateCompany.propTypes = {
  userId: PropTypes.any.isRequired,
}
