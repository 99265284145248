import React from "react"
import PropTypes from "prop-types"
import { Col, Container } from "reactstrap"
import { useTranslation } from "react-i18next"
import { MegaTagTitle, PageHeader } from "components"
import { UserIdeasContainer } from "./UserIdeasContainer"

export const UserIdeas = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.user-ideas"} />

      <div className="page-content">
        <Container fluid>
          <PageHeader title={t("title.user-ideas")} />
          <Col>
            <UserIdeasContainer />
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}
