export const BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY = "monthly"
export const BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY = "annually"

export const BILLING_SUBSCRIPTION_TARIFF_ALIAS_BASIC = "basic"
export const BILLING_SUBSCRIPTION_TARIFF_ALIAS_BUSINESS = "business"

export const BILLING_SUBSCRIPTION_STATUS_ACTIVE = "active"
export const BILLING_SUBSCRIPTION_STATUS_INACTIVE = "inactive"
export const BILLING_SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT =
  "incomplete_payment"
export const BILLING_SUBSCRIPTION_STATUS_TRIALING = "trialing"
export const BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD = "on_grace_period"
