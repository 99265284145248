import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { useTranslation, withTranslation } from "react-i18next"

import SidebarContent from "./SidebarContent"
import { LogoIcon } from "../LogoIcon"

const Sidebar = props => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <LogoIcon size="50" />
            </span>
            <span className="logo-lg">
              <LogoIcon size="70" />
              <div
                style={{
                  lineHeight: "14px",
                  marginTop: "8px",
                  fontWeight: "500",
                }}
              >
                {t("administrator-portal")}
              </div>
            </span>
          </Link>
        </div>
        <div data-simplebar="" className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background" />
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
