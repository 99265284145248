import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { persistStore } from "redux-persist"

import rootReducer from "./reducers"
import rootSaga from "./sagas"
export * from "./actions"
export * from "./selectors"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)
let persistor = persistStore(store)

const fn = () => {
  return { store, persistor }
}
export default fn
