import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Input, Label } from "reactstrap"
import classNames from "classnames"
import { FieldError, FieldHint } from "components"

export const InputField = ({
  id,
  label,
  placeholder,
  type,
  defaultValue,
  fieldRef = null,
  children,
  prependSymbol,
  appendAccessory,
  normalize,
  leftAccessory,
  rightAccessory,
  disabled,
  value,
  meta = {},
  translateParams,
  onChange,
  className = "mb-3",
  hintText,
  ...props
}) => {
  const { touched, error } = meta

  const { t } = useTranslation()
  const showError = Boolean(error)

  const handleChange = event => {
    event.target.value = normalize
      ? normalize(event.target.value)
      : event.target.value

    if (onChange) {
      onChange(event)
    }
  }

  const renderByAccessoryType = accessory => {
    if (accessory) {
      if (typeof accessory === "string") {
        return <i className={accessory} />
      } else return accessory
    } else return <></>
  }

  const renderLabel = label => {
    if (label) {
      if (typeof label === "string") {
        return label
      } else return label
    } else return <></>
  }

  return (
    <div
      className={classNames({
        [className]: className,
      })}
    >
      {label && <Label htmlFor={id}>{renderLabel(label)}</Label>}
      <div
        className={classNames("input-group", {
          "is-invalid": showError,
        })}
      >
        {leftAccessory && renderByAccessoryType(leftAccessory)}

        <Input
          rows={type === "textarea" ? "5" : null}
          {...props}
          type={type}
          className={classNames("form-control", {
            "form-control-right-accessory": rightAccessory,
            "form-control-border-right-round":
              rightAccessory && !appendAccessory,
            "form-control-left-accessory": leftAccessory,
            "form-control-border-left-round": leftAccessory && !appendAccessory,
          })}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          invalid={showError}
          value={value || ""}
          innerRef={fieldRef}
          onChange={event => handleChange(event)}
          onBlur={props.onBlur}
        />

        {rightAccessory && renderByAccessoryType(rightAccessory)}

        {appendAccessory && appendAccessory.map((item, index) => item)}
      </div>

      {hintText && <FieldHint message={hintText} />}

      <FieldError
        error={error}
        translateParams={translateParams}
        showError={showError}
      />
    </div>
  )
}

InputField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.any,
  fieldRef: PropTypes.any,
  children: PropTypes.any,
  prependSymbol: PropTypes.any,
  appendAccessory: PropTypes.array,
  normalize: PropTypes.func,
  rightAccessory: PropTypes.any,
  leftAccessory: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  className: PropTypes.string,
  hintText: PropTypes.string,
}
