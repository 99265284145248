import _ from "lodash"

import {
  CURRENCY_USD,
  CURRENCY_EUR,
  CURRENCY_GBP,
  CURRENCY_CNY,
  CURRENCY_JPY,
  GENERAL_CURRENCY,
} from "consts"

export const getCurrencies = () => {
  return [
    {
      label: CURRENCY_USD,
      value: CURRENCY_USD,
      title: "USA (Dollar)",
      symbol: "$",
    },
    {
      label: CURRENCY_EUR,
      value: CURRENCY_EUR,
      title: "Euro",
      symbol: "€",
    },
    {
      label: CURRENCY_GBP,
      value: CURRENCY_GBP,
      title: "UK (Pound)",
      symbol: "£",
    },
    {
      label: CURRENCY_CNY,
      value: CURRENCY_CNY,
      title: "Yuan (Chinese)",
      symbol: "¥",
    },
    {
      label: CURRENCY_JPY,
      value: CURRENCY_JPY,
      title: "Yen (Japanese)",
      symbol: "¥",
    },
  ]
}

export const getCurrency = value => {
  const options = getCurrencies()
  return _.find(options, { value: value })
}

export const getGeneralCurrency = () => {
  return getCurrency(GENERAL_CURRENCY)
}
