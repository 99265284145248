import { all } from "redux-saga/effects"

import updateSaga from "./update/saga"
import removeSaga from "./remove/saga"
import listSaga from "./list/saga"
import createSaga from "./create/saga"

function* translationSaga() {
  yield all([updateSaga(), removeSaga(), listSaga(), createSaga()])
}

export default translationSaga
