import React, { useReducer } from "react"
import { API } from "api"
import { CancelToken } from "axios"

const initialState = {
  loading: false,
  imageDataURL: null,
  error: null,
}

function reducer(state, action) {
  switch (action.type) {
    case "getStarted":
      return {
        ...state,
        loading: true,
      }
    case "getCompleted":
      return {
        ...state,
        loading: false,
        imageDataURL: action.payload,
      }
    case "getRejected":
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case "setLocalImage": {
      return {
        ...state,
        imageDataURL: action.payload,
      }
    }
    case "cleanState":
      return initialState
    default:
      return state
  }
}

export const useFetchImage = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const source = CancelToken.source()

  const fetchImage = async imageUrl => {
    if (!imageUrl) {
      return
    }
    dispatch({ type: "getStarted" })

    try {
      const response = await API.storage.getImage(imageUrl, {
        cancelToken: source.token,
      })
      const { data } = response
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch({ type: "getCompleted", payload: reader.result })
        }
      }
      reader.readAsDataURL(data)
    } catch (error) {
      if (error.message !== "cancel") {
        dispatch({ type: "getRejected", payload: error.message })
      }
    }
  }
  const cancelFetch = () => {
    source.cancel("cancel")
  }
  const cleanState = () => {
    dispatch({ type: "cleanState" })
  }
  const setLocalImage = imageDataURL => {
    dispatch({ type: "setLocalImage", payload: imageDataURL })
  }

  return { state, fetchImage, cancelFetch, cleanState, setLocalImage }
}
