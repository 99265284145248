import { combineReducers } from "redux"

import list from "./list/slice"

import remove from "./remove/slice"
import update from "./update/slice"
import create from "./create/slice"

const translationReducer = combineReducers({
  list,
  remove,
  update,
  create,
})

export default translationReducer
