import axios from "axios"

class StorageAPI {
  getImage = async (imageUrl, config) => {
    return axios.get(`${imageUrl}`, {
      responseType: "blob",
      ...config,
    })
  }
}

export const storage = new StorageAPI()
