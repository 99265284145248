import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Modal, Button } from "components"
import { useSubmitHandler, useModal } from "hooks"
import { UserGenerateForm } from "./UserGenerateForm"
import { userAccountsGenerate } from "store/actions"

export const UserGenerate = ({ onSuccess }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.userAccounts.generate)

  const { modalRef, closeModal, showModal } = useModal()

  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      if (modalVisible) {
        dispatch(userAccountsGenerate.cleanState())
      }
    }
  }, [modalVisible])

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        userAccountsGenerate.create({
          params,
          onSuccess,
          onError
        })
      )
    },
    () => {
      onSuccess()
    }
  )

  return (
    <>
      <Button
        color="secondary"
        leftIcon={"bx bx-plus"}
        title={"new_user"}
        onClick={showModal}
      />

      <Modal
        ref={modalRef}
        title={t("new_user")}
        onVisibleChange={value => setModalVisible(value)}
        strapModalProp={{
          centered: true
        }}
      >
        <UserGenerateForm
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
          onCancel={closeModal}
        />
      </Modal>
    </>
  )
}

UserGenerate.propTypes = {
  onSuccess: PropTypes.func
}
