import axios from "axios"
import { RequestUtils } from "utils"

class WebsiteAPI {
  //================== GENERAl ===================//
  getWebsiteData = async () => {
    return axios.get(`api/website`)
  }
  createWebsite = async params => {
    return axios.post(`api/website`, params)
  }
  updateWebsite = async params => {
    return axios.put(`api/website`, params)
  }
  removeWebsite = async () => {
    return axios.delete(`api/website`)
  }
  getWebsitePages = async () => {
    return axios.get(`api/website/pages`)
  }
  //================== AVATAR ===================//
  uploadWebsiteAvatar = async params => {
    let formData = new FormData()
    formData.append("avatar", params.avatar)
    return axios.post(`api/website/avatar`, formData)
  }
  removeWebsiteAvatar = async () => {
    return axios.delete(`api/website/avatar`)
  }
  //================== MENU-ITEMS ===============//
  getWebsiteMenuItems = async params => {
    return axios.get(`api/website/menu-items`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  addWebsiteMenuItem = async params => {
    return axios.post(`api/website/menu-items`, params)
  }
  updateWebsiteMenuItem = async (menuId, params) => {
    return axios.put(`api/website/menu-items/${menuId}`, params)
  }
  removeWebsiteMenuItem = async menuId => {
    return axios.delete(`api/website/menu-items/${menuId}`)
  }
  reorderMenuItems = async params => {
    return axios.post(`api/website/menu-items/reorder`, params)
  }
  //================== CURRENT PAGE ===============//
  getCurrentPageData = async alias => {
    return axios.get(`api/website/pages/${alias}`)
  }
  updateCurrentPageData = async (alias, params) => {
    return axios.put(`api/website/pages/${alias}`, params)
  }
  addCurrentPageAttachments = async (alias, params, onUploadProgress) => {
    let formData = new FormData()
    formData.append("attachment", params.attachment)
    return axios.post(`api/website/pages/${alias}/attachments`, formData, {
      onUploadProgress,
    })
  }
  removeCurrentPageAttachment = async id => {
    return axios.delete(`api/website/pages/attachments/${id}`)
  }
  //================== WEBSITE-THEMES ===============//
  getWebsiteThemes = async () => {
    return axios.get(`api/website-themes`)
  }
  //=================== REQUESTS ======================//
  getWebsiteRequests = async params => {
    return axios.get(`api/website/request`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  removeWebsiteRequest = async requestId => {
    return axios.delete(`api/website/request/${requestId}`)
  }
}

export const website = new WebsiteAPI()
