import React from "react"
import { connect } from "react-redux"
import { Switch, withRouter } from "react-router-dom"

import { publicRoutes } from "../../routes/allRoutes"
import { AuthLayout } from "../../components"
import { PublicRoute } from "../../routes/middleware"

const AuthIndexComponent = props => {
  return (
    <AuthLayout>
      <Switch>
        {publicRoutes.map((route, idx) => (
          <PublicRoute
            path={route.path}
            component={route.component}
            key={idx}
          />
        ))}
      </Switch>
    </AuthLayout>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const AuthIndex = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthIndexComponent)
)
