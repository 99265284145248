import axios from "axios"

import { RequestUtils } from "../../utils"

class AdminAPI {
  //====================GENERAL===========================//
  getUserAccounts = async params => {
    return axios.get("api/admin/users", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  getUserAccount = async (id, params) => {
    return axios.get(`api/admin/users/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  generateUser = async params => {
    return axios.post(`api/admin/generate-user`, params)
  }

  //====================User Ideas===========================//
  getUserIdeas = async params => {
    return axios.get(`api/admin/user-ideas`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  getUserIdea = async id => {
    return axios.get(`api/admin/user-ideas/${id}`)
  }
  updateUserIdea = async (id, params) => {
    return axios.put(`api/admin/user-ideas/${id}`, params)
  }
  deleteUserIdea = async id => {
    return axios.delete(`api/admin/user-ideas/${id}`)
  }

  //====================STATIC PAGES===========================//
  getStaticPages = async () => {
    return axios.get(`api/admin/static-pages`)
  }
  addStaticPage = async params => {
    return axios.post(`api/admin/static-pages`, params)
  }
  getStaticPageById = async pageId => {
    return axios.get(`api/admin/static-pages/${pageId}`)
  }
  getStaticPageByAlias = async pageAlias => {
    return axios.get(`api/static-pages/${pageAlias}`)
  }
  updateStaticPage = async (pageId, params) => {
    return axios.put(`api/admin/static-pages/${pageId}`, params)
  }
  removeStaticPage = async pageId => {
    return axios.delete(`api/admin/static-pages/${pageId}`)
  }

  //====================DEACTIVATE COMPANY===========================//
  deactivateCompany = async userId => {
    return axios.post(`api/admin/users/${userId}/company/deactivate`)
  }
  activateCompany = async userId => {
    return axios.post(`api/admin/users/${userId}/company/activate`)
  }

  deleteUser = async userId => {
    return axios.delete(`api/admin/users/${userId}`)
  }
}

export const admin = new AdminAPI()
