import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import removeSaga from "./remove/saga"
import itemSaga from "./item/saga"
import updateSaga from "./update/saga"
import addSaga from "./add/saga"

function* staticPagesSaga() {
  yield all([listSaga(), removeSaga(), itemSaga(), updateSaga(), addSaga()])
}

export default staticPagesSaga
