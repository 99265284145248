import React from "react"
import { Spinner } from "reactstrap"
import PropTypes from "prop-types"

export const TableDataLoading = ({ isLoading }) => {
  return (
    <>
      {isLoading ? (
        <div className={"table-loader"}>
          <Spinner size={"lg"} color="primary" />
        </div>
      ) : null}
    </>
  )
}

TableDataLoading.propTypes = {
  isLoading: PropTypes.bool,
}
