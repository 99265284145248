import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const userProfileDataSlice = createSlice({
  name: "userProfileData",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = { ...action.payload }
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} setData
 *  @property {function} updateData
 *  @property {function} cleanState
 */
export const userProfileData = userProfileDataSlice.actions

export default userProfileDataSlice.reducer
