import React from "react"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"
import classnames from "classnames"

export const CroppedImage = ({
  type,
  crops,
  baseSrc,
  placeholder,
  alt = "photo",
}) => {
  const avatar_square = crops
    ? crops.find(crop => crop.type === "square")
    : null
  const avatar_rectangle = crops
    ? crops.find(crop => crop.type === "rectangle")
    : null

  const getSrc = type => {
    if (type === "square" && avatar_square) {
      return avatar_square.src
    }
    if (type === "rectangle" && avatar_rectangle) {
      return avatar_rectangle.src
    }
    if (baseSrc) {
      return baseSrc
    } else {
      return placeholder
    }
  }

  return (
    <LazyLoadImage
      alt={alt}
      src={getSrc(type)}
      effect="skeleton"
      wrapperClassName={classnames("avatar-wrap", {
        "avatar-wrap_placeholder": !baseSrc,
      })}
    />
  )
}

CroppedImage.propTypes = {
  type: PropTypes.oneOf(["square", "rectangle"]),
  crops: PropTypes.array,
  baseSrc: PropTypes.string,
  alt: PropTypes.string,
  placeholder: PropTypes.any,
}
