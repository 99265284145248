import { call, takeEvery, fork, put, all } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { LOGOUT_USER } from "./actionTypes"
import { logoutUserSuccess } from "./actions"
import { cleanAuthData } from "../data/actions"
import { API } from "api"
import { userProfileData } from "store/user/actions"

function* logoutUser({ payload: { history, onSuccess } }) {
  try {
    const response = yield call(API.auth.logOut)
    const { data } = response
    yield put(logoutUserSuccess())

    yield put(cleanAuthData())
    yield put(userProfileData.cleanState())

    history && history.push("/login")

    onSuccess && onSuccess()
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    toastr.error(message)
  }
}

export function* watchLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser)
}

function* logoutSaga() {
  yield all([fork(watchLogout)])
}

export default logoutSaga
