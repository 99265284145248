import React from "react"

import { APP_NAME } from "../../../../consts"

const FooterComponent = () => {
  return (
    <div className="text-center">
      <p>
        © {new Date().getFullYear()} {APP_NAME}
      </p>
    </div>
  )
}

export const Footer = FooterComponent
