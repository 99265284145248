import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const deactivateCompanySlice = createSlice({
  name: "deactivateCompany",
  initialState,
  reducers: {
    deactivate(state) {
      state.loading = true
      state.error = null
    },
    deactivateCompleted(state) {
      state.loading = false
    },
    deactivateRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} deactivate
 *  @property {function} deactivateCompleted
 *  @property {function} deactivateRejected
 *  @property {function} cleanState
 */
export const deactivateCompany = deactivateCompanySlice.actions

export const selectDeactivateCompany = state => state.userAccounts.deactivate

export default deactivateCompanySlice.reducer
