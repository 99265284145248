import { all } from "redux-saga/effects"

import loadSaga from "./load/saga"
import settingsSaga from "./settings/sagas"

function* userSaga() {
  yield all([loadSaga(), settingsSaga()])
}

export default userSaga
