import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const fetchUserDataSlice = createSlice({
  name: "fetchUserData",
  initialState,
  reducers: {
    fetch(state) {
      state.loading = true
      state.error = null
    },
    fetchCompleted(state) {
      state.loading = false
    },
    fetchRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} fetch
 *  @property {function} fetchCompleted
 *  @property {function} fetchRejected
 *  @property {function} cleanState
 */

export const fetchUserData = fetchUserDataSlice.actions

export default fetchUserDataSlice.reducer
