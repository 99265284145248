import React, { createContext, useContext, useState } from "react"
import PropTypes from "prop-types"

const PanelSidebarContext = createContext({
  options: {},
  setOptions: () => {},
})

const PanelSidebarProvider = ({ children }) => {
  const [options, setOptions] = useState({})

  return (
    <PanelSidebarContext.Provider value={{ options, setOptions }}>
      {children}
    </PanelSidebarContext.Provider>
  )
}

PanelSidebarProvider.propTypes = {
  children: PropTypes.any,
}

const usePanelSidebarContext = () => useContext(PanelSidebarContext)

export { PanelSidebarProvider, usePanelSidebarContext }
