/* eslint react/prop-types: off */
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Row, Col, Card, CardBody } from "reactstrap"
import moment from "moment"
import {
  ReactTableView,
  Pagination,
  CroppedImage,
  ReadMoreExpand,
  IconButton,
} from "components"
import * as HOC from "HOC"
const TableDataLoading = HOC.withTableDataLoading()
import placeholder from "assets/images/dev/avatar-placeholder-square.svg"

export const UserIdeasTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.entities
  }, [props.entities])

  const [columns, setColumns] = useState([
    {
      Header: t("name"),
      accessor: "user",
      id: "user",
      Cell: ({ row: { original } }) => {
        return (
          <div className={"d-flex"}>
            <div className={"avatar-rounded avatar-rounded__without-crop mr-2"}>
              <CroppedImage
                key={original.id}
                placeholder={placeholder}
                baseSrc={original.user?.avatar}
                alt={original.user?.name}
              />
            </div>
            <div className={"align-self-center"}>{original.user?.name}</div>
          </div>
        )
      },
    },
    {
      Header: t("date"),
      accessor: "created_at",
      id: "created_at",
      Cell: ({ value }) => {
        return value ? moment(value).format("DD/MM/YYYY") : "-"
      },
    },
    {
      Header: t("text"),
      accessor: "message",
      id: "message",
      Cell: ({ value }) =>
        value ? (
          <ReadMoreExpand maxHeight={40} lineHeight={20}>
            {value}
          </ReadMoreExpand>
        ) : (
          "-"
        ),
    },
    {
      Header: t("email"),
      accessor: "email",
      id: "email",
      Cell: ({ row: { original } }) => original.user?.email,
    },
    {
      Header: "",
      accessor: "remove",
      id: "remove",
      Cell: ({ row: { original } }) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <IconButton
              name={"remove"}
              bg
              onClick={() => props.onRemove(original)}
              disabled={props.removingLoading}
            />
          </div>
        )
      },
    },
  ])

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody className={"bg-neutral"}>
            <TableDataLoading
              isLoading={props.loading}
              isFailed={props.error}
              error={props.error}
            >
              <ReactTableView
                isLoading={props.loading}
                columns={columns}
                data={data}
              />

              <Pagination
                data={props.meta}
                onPageChange={props.onPageChange}
                className={"mt-2"}
              />
            </TableDataLoading>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

UserIdeasTable.propTypes = {
  entities: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  onPageChange: PropTypes.func,
  onRemove: PropTypes.func,
  removingLoading: PropTypes.bool,
}
