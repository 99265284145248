import React from "react"
import PropTypes from "prop-types"
import { Nav } from "../Nav"
import { PageHeader } from "components"
import { useTranslation } from "react-i18next"

export const Header = () => {
  const { t } = useTranslation()
  return (
    <div className={"panel-topbar"}>
      <PageHeader title={t("pages")} />
      <div className={"panel-topbar__nav mb-4"}>
        <div className={"panel-topbar__menu"}>
          <Nav />
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  model: PropTypes.object,
  topBarOptions: PropTypes.object,
}
