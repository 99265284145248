import React, { useMemo } from "react"
import PropTypes from "prop-types"
import Menu, { MenuItem } from "rc-menu"
import { Link, useLocation } from "react-router-dom"

export const PanelMenu = props => {
  const location = useLocation()

  const detectedSelectedKey = useMemo(() => {
    const pathName = location.pathname

    for (let i = 0; i < props.items.length; i++) {
      const item = props.items[i]
      const resultIndex = pathName.indexOf(item.route)
      if (resultIndex > -1) {
        return item.key
      }
    }

    return null
  }, [location])

  const renderMenuItem = () =>
    props.items.map(item => (
      <MenuItem key={item.key}>
        <Link
          to={{
            pathname: item.route,
            state: item.stateParams ? { ...item.stateParams } : null,
          }}
        >
          {item.title}

          {item.badge}
        </Link>
      </MenuItem>
    ))

  return (
    <>
      <Menu
        mode="horizontal"
        selectedKeys={[detectedSelectedKey]}
        className={"panel-menu"}
        overflowedIndicatorPopupClassName="panel-menu__popup"
        triggerSubMenuAction={"click"}
        overflowedIndicator={<i className="bx bx-dots-vertical-rounded" />}
        // motion={{ motionName: `rc-menu-open-slide-up` }}
      >
        {renderMenuItem()}
      </Menu>
    </>
  )
}

PanelMenu.propTypes = {
  items: PropTypes.array,
}
