import React from "react"
import { Alert } from "reactstrap"

import i18n from "../i18n"
import { TableDataLoading } from "../components"

export default () => {
  const anon = ({ isLoading, isFailed = false, error, children, ...rest }) =>
    !isFailed ? (
      <div className={"table-wrap"}>
        {children}

        <TableDataLoading isLoading={isLoading} />
      </div>
    ) : (
      <Alert color={"danger"}>{error || i18n.t("error")}</Alert>
    )

  return anon
}
