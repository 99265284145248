export const FACEBOOK = "facebook"
export const TWITTER = "twitter"
export const TELEGRAM = "telegram"
export const FACEBOOK_MESSENGER = "facebook messenger"
export const VIBER = "viber"
export const WHATSAPP = "whatsapp"
export const INSTAGRAM = "instagram"
export const YOUTUBE = "youtube"
export const LINKEDIN = "linkedin"
export const TIKTOK = "tiktok"

export const SOCIAL_NETWORK_LIST = [
  WHATSAPP,
  TELEGRAM,
  VIBER,
  FACEBOOK,
  TWITTER,
  LINKEDIN,
  TIKTOK,
  YOUTUBE,
  INSTAGRAM,
]

export const SOCIAL_NETWORK_PUBLISH_LIST = [FACEBOOK, INSTAGRAM]

export const SOCIAL_NETWORK_SHARE_LIST = [FACEBOOK, TWITTER]
