import Echo from "laravel-echo"
import axios from "axios"

const KEY = process.env.REACT_APP_PUSHER_KEY
const CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER
const AUTH_URL = process.env.REACT_APP_BASE_URL + "api/broadcasting/auth"

window.Pusher = require("pusher-js")

const options = {
  broadcaster: "pusher",
  key: KEY,
  cluster: CLUSTER,
  forceTLS: true,
  authEndpoint: AUTH_URL,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        axios
          .post("/api/broadcasting/auth", {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then(response => {
            callback(false, response.data)
          })
          .catch(error => {
            callback(true, error)
          })
      },
    }
  },
}

class EchoService {
  echo = null

  getEcho = () => {
    return this.echo
  }

  create = () => {
    this.echo = new Echo(options)
  }

  privateChannelAddListener = (channel, event, callback) => {
    const echo = this.getEcho()

    if (echo && channel && event && callback) {
      echo.private(channel).listen(event, callback)
    }
  }

  privateChannelNotification = (channel, callback) => {
    const echo = this.getEcho()

    if (echo && channel && callback) {
      echo.private(channel).notification(callback)
    }
  }

  privateChannelAddListeners = (channel, events) => {
    const echo = this.getEcho()

    if (echo && channel && events) {
      Object.entries(events).forEach(entry => {
        const [event, callback] = entry
        this.privateChannelAddListener(channel, event, callback)
      })
    }
  }

  privateChannelStopListener = (channel, event) => {
    const echo = this.getEcho()

    if (echo && channel && event) {
      echo.private(channel).stopListening(event)
    }
  }

  privateChannelStopListeners = (channel, events) => {
    const echo = this.getEcho()

    if (echo && channel && events) {
      events.forEach(event => {
        this.privateChannelStopListener(channel, event)
      })
    }
  }

  channelLeave = channel => {
    const echo = this.getEcho()
    if (echo) {
      echo.leave(channel)
    }
  }

  channelLeaveAll = () => {
    const echo = this.getEcho()

    if (echo && echo.connector && echo.connector.channels) {
      for (const key in echo.connector.channels) {
        if (echo.connector.channels.hasOwnProperty(key)) {
          echo.leave(key)
        }
      }
    }
  }

  destroy = () => {
    this.echo = null
  }
}

const EchoClient = new EchoService()
export default EchoClient
