import React from "react"
import PropTypes from "prop-types"
import { Route, withRouter } from "react-router-dom"
import { connect } from "react-redux"

const ProtectedRouteComponent = ({
  component: Component,
  path,
  middlewares = [],
  ...rest
}) => {
  const withMiddleware = middleware => {
    return middlewares.includes(middleware)
  }

  return (
    <Route {...rest}>
      <Component {...rest} />
    </Route>
  )
}

ProtectedRouteComponent.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
  middlewares: PropTypes.array,

  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.user.profile
  return {
    user: data,
  }
}

export const ProtectedRoute = withRouter(
  connect(mapStateToProps, {})(ProtectedRouteComponent)
)
