import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { TranslationForm } from "../../TranslationForm"
import { ValidationsUtils } from "utils"
import { translationUpdate } from "store"

const TranslationUpdateContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const onDestroy = () => {
    dispatch(translationUpdate.cleanState())
  }

  useEffect(() => {
    return () => {
      onDestroy()
    }
  }, [])

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onSuccess()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      translationUpdate.update({
        id: props.data?.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  return (
    <TranslationForm
      initialValues={{
        ...props.data,
      }}
      loading={props.loading}
      error={props.error}
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
    />
  )
}

TranslationUpdateContainerComponent.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onSuccess: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.translation.update
  return {
    loading,
    error,
  }
}

export const TranslationUpdateContainer = connect(mapStateToProps)(
  TranslationUpdateContainerComponent
)
