import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { userAccountsGenerate } from "./slice"

function* create({ payload: { params, onSuccess, onError } }) {
  try {
    const response = yield call(API.admin.generateUser, params)

    const { data } = response

    yield put(userAccountsGenerate.createSuccess())

    toastr.success(i18n.t("added_success"))

    if (onSuccess) {
      onSuccess(data?.data)
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      userAccountsGenerate.createError({
        errors,
        message
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchCreate() {
  yield takeEvery(userAccountsGenerate.create, create)
}

function* generateSaga() {
  yield all([fork(watchCreate)])
}

export default generateSaga
