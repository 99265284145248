import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react"
import PropTypes from "prop-types"
import {
  Modal as StrapModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { withTranslation } from "react-i18next"

const CustomModalComponent = ({
  parentRef,
  initialValue = false,
  onVisibleChange,
  title,
  size = "lg",
  children,
  className = "modal-block",
  strapModalProp,
  closable = true,
}) => {
  const [modalVisible, setModalVisible] = useState(initialValue)

  useImperativeHandle(parentRef, () => ({
    show() {
      showModal()
    },
    hide() {
      hideModal()
    },
  }))

  useEffect(() => {
    if (onVisibleChange) {
      onVisibleChange(modalVisible)
    }
  }, [modalVisible])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const toggle = () => {
    setModalVisible(!modalVisible)
  }

  const closeBtn = (
    <button
      type="button"
      className="btn-close"
      data-dismiss="modal"
      aria-label="Close"
      onClick={toggle}
    ></button>
  )

  return (
    <StrapModal
      size={size}
      isOpen={modalVisible}
      toggle={closable ? toggle : null}
      className={className}
      {...strapModalProp}
    >
      <ModalHeader
        toggle={closable ? toggle : null}
        close={closable ? closeBtn : null}
      >
        {title}
      </ModalHeader>

      <ModalBody>{children}</ModalBody>

      <ModalFooter />
    </StrapModal>
  )
}

CustomModalComponent.propTypes = {
  parentRef: PropTypes.any,
  initialValue: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  title: PropTypes.any,
  size: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  strapModalProp: PropTypes.any,
  closable: PropTypes.bool,
}

const CustomModalHOC = withTranslation()(CustomModalComponent)

export const Modal = forwardRef((props, ref) => (
  <CustomModalHOC {...props} parentRef={ref} />
))
