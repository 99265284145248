import {
  BILLING_SUBSCRIPTION_STATUS_ACTIVE,
  BILLING_SUBSCRIPTION_STATUS_INACTIVE,
  BILLING_SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT,
  BILLING_SUBSCRIPTION_STATUS_TRIALING,
  BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD,
} from "consts"
import i18n from "../../i18n"

export const getStatus = value => {
  let data = {
    label: "",
    value: "",
    color: "",
  }
  switch (value) {
    case BILLING_SUBSCRIPTION_STATUS_ACTIVE:
      data = {
        label: i18n.t("billing_subscription.status.active"),
        value: BILLING_SUBSCRIPTION_STATUS_ACTIVE,
        color: "success",
      }
      break

    case BILLING_SUBSCRIPTION_STATUS_INACTIVE:
      data = {
        label: i18n.t("billing_subscription.status.inactive"),
        value: BILLING_SUBSCRIPTION_STATUS_INACTIVE,
        color: "secondary",
      }
      break

    case BILLING_SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT:
      data = {
        label: i18n.t("billing_subscription.status.incomplete_payment"),
        value: BILLING_SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT,
        color: "secondary",
      }
      break

    case BILLING_SUBSCRIPTION_STATUS_TRIALING:
      data = {
        label: i18n.t("billing_subscription.status.trialing"),
        value: BILLING_SUBSCRIPTION_STATUS_TRIALING,
        color: "warning",
      }
      break

    case BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD:
      data = {
        label: i18n.t("billing_subscription.status.on_grace_period"),
        value: BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD,
        color: "secondary",
      }
      break

    default:
      data = { ...data }
      break
  }
  return data
}

export default {
  getStatus,
}
