import { all } from "redux-saga/effects"

import loginSaga from "./login/saga"
import logoutSaga from "./logout/saga"

function* authSaga() {
  yield all([loginSaga(), logoutSaga()])
}

export default authSaga
