import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

const userIdeasListAdapter = createEntityAdapter()

const initialState = userIdeasListAdapter.getInitialState({
  loading: false,
  error: null,
  page: 1,
  meta: null,
  sort: [{ id: "created_at", desc: true }],
})

const userIdeasListSlice = createSlice({
  name: "userIdeasList",
  initialState,
  reducers: {
    getList(state) {
      state.loading = true
      state.error = null
    },
    getListCompleted(state, action) {
      state.loading = false
      userIdeasListAdapter.setAll(state, action.payload.data)
      state.meta = action.payload.meta
    },
    getListRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    changeSort(state, action) {
      state.page = 1
      state.sort = action.payload.sort
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 *  @property {function} setPage
 *  @property {function} cleanState
 */
export const userIdeasList = userIdeasListSlice.actions

export const selectUserIdeasList = state => state.userIdeas.list

export const { selectAll: selectAllUserIdeas } =
  userIdeasListAdapter.getSelectors(state => state.userIdeas.list)

export default userIdeasListSlice.reducer
