import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { useEcho } from "hooks"
import { socketOnUserLogin } from "store/actions"
import { ECHO_EVENT } from "consts"

export const SocketMiddleware = props => {
  const echo = useEcho()

  const history = useHistory()

  const dispatch = useDispatch()
  const { data: user } = useSelector(state => state.user.profile)

  useEffect(() => {
    echo.create()

    if (user?.id) {
      echo.privateChannelAddListeners(`user.${user.id}`, {
        // when user session expired and this user login via other device
        [ECHO_EVENT.LOGIN_USER_EVENT]: data =>
          dispatch(socketOnUserLogin({ data, history })),
      })
    }

    return () => {
      echo.channelLeaveAll()

      echo.destroy()
    }
  }, [])

  return props.children
}
