import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useDeepCompareEffect, useFirstMountState } from "react-use"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Row } from "reactstrap"

import { TranslationUpdate } from "./TranslationUpdate"
import { useConfirmModal, useModal } from "hooks"
import { GeneralFilter } from "./Filters"
import { translationRemove, translationList } from "store"
import { ConfirmPopup, PageHeader, Pagination } from "components"
import { Header } from "./Header"
import { TranslationsTable } from "./TranslationsTable"
import * as HOC from "HOC"

const TableDataLoading = HOC.withTableDataLoading()

const TranslationsContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const isFirstMount = useFirstMountState()

  useEffect(() => {
    fetchData()

    return () => {
      dispatch(translationList.cleanState())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      fetchData()
    }
  }, [props.filters.values, props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
    }
    const { keywords } = props.filters.values

    params.filters = {
      keywords: keywords !== "" ? keywords : null,
    }

    dispatch(translationList.getList({ params }))
  }

  const handleSearch = value => {
    dispatch(translationList.changeFilter({ keywords: value }))
  }

  const handlePageChange = item => {
    dispatch(translationList.setPage(item.selected + 1))
  }

  //=======================REMOVE LOGIC ==============================//
  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    item => {
      dispatch(
        translationRemove.remove({ id: item.id, onSuccess: onRemoveSuccess })
      )
    },
    () => {
      dispatch(translationRemove.cleanState())
    }
  )
  const onRemoveSuccess = () => {
    fetchData()
  }

  //=============================UPDATE LOGIC ==============================//
  const {
    modalRef: editModalRef,
    closeModal: editCloseModal,
    showModal: editShowModal,
    item: editItem,
  } = useModal()
  //===========================================================//

  return (
    <>
      <PageHeader
        title={t("translations")}
        headerRight={<Header onCreateSuccess={fetchData} />}
      />

      <GeneralFilter onSearch={handleSearch} />
      <div className={"mb-2"} />

      <Row>
        <Col>
          <Card>
            <CardBody className={"bg-neutral"}>
              <TableDataLoading
                isLoading={props.listLoading || props.removeLoading}
                isFailed={props.error}
                error={props.error}
              >
                <TranslationsTable
                  list={props.list}
                  loading={props.listLoading}
                  onEdit={editShowModal}
                  onRemove={showModal}
                />

                <Pagination
                  data={props.meta}
                  onPageChange={handlePageChange}
                  className={"mt-2"}
                />
              </TableDataLoading>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <TranslationUpdate
        modalRef={editModalRef}
        data={editItem}
        onSuccess={editCloseModal}
        onClose={editCloseModal}
      />

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      />
    </>
  )
}

TranslationsContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  page: PropTypes.any,
  filters: PropTypes.object,
  removeLoading: PropTypes.bool,
  error: PropTypes.object,
  meta: PropTypes.any,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page, filters } =
    state.translation.list
  const { loading: removeLoading } = state.translation.remove
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
    filters,
    removeLoading,
  }
}

export const TranslationsContainer = connect(mapStateToProps)(
  TranslationsContainerComponent
)
