/* eslint react/prop-types: off */
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Row, Col, Card, CardBody } from "reactstrap"

import { ReactTableView } from "../../ReactTableView"
import { Pagination } from "../../Pagination"

import * as HOC from "../../../HOC"
import { commonHelper } from "../../../helpers"
import { IconButton } from "../../Buttons"

const TableDataLoading = HOC.withTableDataLoading()

export const PricesTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns, setColumns] = useState([
    {
      Header: t("label"),
      accessor: "label",
      id: "label",
      Cell: ({ value }) => {
        return value
      },
    },
    {
      Header: t("type-quantity"),
      accessor: "type_quantity",
      id: "type_quantity",
      Cell: ({ value }) => value,
    },
    {
      Header: t("price"),
      accessor: "price",
      id: "price",
      Cell: ({ row: { original }, value }) => {
        let currency = commonHelper.currency.getCurrency(original.currency)
        return `${currency.value?.toUpperCase()} ${value}`
      },
    },
    {
      Header: "",
      accessor: "edit",
      id: "edit",
      Cell: ({ row: { original } }) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <IconButton
              name={"edit"}
              bg
              onClick={() => props.onUpdate(original)}
              disabled={props.removingLoading}
            />
            <IconButton
              name={"remove"}
              bg
              onClick={() => props.onRemove(original)}
              wrapClass={"ms-2"}
              disabled={props.removingLoading}
            />
          </div>
        )
      },
    },
  ])

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody className={"bg-neutral"}>
            <TableDataLoading
              isLoading={props.loading}
              isFailed={props.error}
              error={props.error}
            >
              <ReactTableView
                isLoading={props.loading}
                columns={columns}
                data={data}
              />

              <Pagination
                data={props.meta}
                onPageChange={props.onPageChange}
                className={"mt-2"}
              />
            </TableDataLoading>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

PricesTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onPageChange: PropTypes.func,
  removingLoading: PropTypes.bool,
}
