import React from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Container, Alert, Button } from "reactstrap"
import { useTranslation } from "react-i18next"

import { logoutUser } from "../../../../store/actions"

export const ErrorCard = props => {
  const { t } = useTranslation()
  const history = useHistory()

  const dispatch = useDispatch()

  const logOut = () => {
    dispatch(logoutUser({}))
  }

  return (
    <Container>
      {props.error && (
        <>
          <Alert color="danger mt-5">{props.error}</Alert>
          <Button type="button" color="primary-green" onClick={logOut}>
            {t("go_to")} {t("login")}
          </Button>
        </>
      )}
    </Container>
  )
}

ErrorCard.propTypes = {
  error: PropTypes.any,
}
