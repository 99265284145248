import React from "react"
import PropTypes from "prop-types"

import { FormatUtils } from "../../utils"

export const PhoneFormat = ({ value }) => {
  return <>{value !== null ? FormatUtils.formatPhone(value) : "-"}</>
}

PhoneFormat.propTypes = {
  value: PropTypes.any,
}
