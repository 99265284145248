import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"

import iconBack from "../../../assets/images/dev/icon-chevron-left.svg"

export const BackButton = ({ onBack, title }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleBack = e => {
    e.preventDefault()

    if (onBack) {
      onBack()
    } else {
      history.goBack()
    }
  }

  return (
    <Link to={"#"} onClick={handleBack} className={"text-dark pe-1 me-3"}>
      <img src={iconBack} alt={"back"} />

      {title && <span className={"ms-3"}>{title}</span>}
    </Link>
  )
}

BackButton.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.any,
}
