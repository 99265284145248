import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  filesUploading: false,
  filesProgressList: [],
}

const multipleUploadSlice = createSlice({
  name: "multipleUpload",
  initialState,
  reducers: {
    addFiles(state, action) {
      //add initial loading state for list
      state.filesUploading = true
      //set initial progress states for each file
      state.filesProgressList = action.payload.filesList.map(fileData => {
        return {
          id: fileData.fileConfig.id,
          name: fileData.fileConfig.name,
          size: fileData.fileConfig.size,
          progress: 0,
          error: null,
          loading: false,
          isUploaded: false,
        }
      })
    },
    addFilesCompleted(state, action) {
      //when all files are uploaded
      state.filesUploading = false
    },
    addFileStarted(state, action) {
      //one of files started uploading
      state.filesProgressList = state.filesProgressList.map(file => {
        if (file.id === action.payload.id) {
          return { ...file, loading: true }
        } else {
          return file
        }
      })
    },
    addFileCompleted(state, action) {
      //file finished  uploading
      state.filesProgressList = state.filesProgressList.map(file => {
        if (file.id === action.payload.id) {
          return { ...file, loading: false, isUploaded: true }
        } else {
          return file
        }
      })
    },
    addFileRejected(state, action) {
      //add error to file progress object when one is rejected
      state.filesProgressList = state.filesProgressList.map(file => {
        if (file.id === action.payload.id) {
          return {
            ...file,
            loading: false,
            error: action.payload.message,
          }
        } else {
          return file
        }
      })
    },
    removeFile(state, action) {
      state.filesProgressList = state.filesProgressList.filter(
        file => file.id !== action.payload.id
      )
    },
    updateFile(state, action) {
      //update file object progress
      state.filesProgressList = state.filesProgressList.map(file => {
        if (file.id === action.payload.id) {
          return { ...file, ...action.payload }
        } else {
          return file
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} addFiles
 * @property {function} addFilesCompleted
 * @property {function} addFileStarted
 * @property {function} addFileCompleted
 * @property {function} addFileRejected
 * @property {function} removeFile
 * @property {function} updateFile
 *  @property {function} cleanState
 */
export const multipleUpload = multipleUploadSlice.actions

export default multipleUploadSlice.reducer
