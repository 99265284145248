import { all } from "redux-saga/effects"

import measurementSaga from "./measurement/sagas"
import countrySaga from "./country/sagas"

function* staticSaga() {
  yield all([measurementSaga(), countrySaga()])
}

export default staticSaga
