import React, { createContext, useContext, useState } from "react"
import PropTypes from "prop-types"

const PanelTopBarContext = createContext({
  options: {},
  setOptions: () => {},
})

const PanelTopBarProvider = ({ children }) => {
  const [options, setOptions] = useState({})

  return (
    <PanelTopBarContext.Provider value={{ options, setOptions }}>
      {children}
    </PanelTopBarContext.Provider>
  )
}

PanelTopBarProvider.propTypes = {
  children: PropTypes.any,
}

const usePanelTopBarContext = () => useContext(PanelTopBarContext)

export { PanelTopBarProvider, usePanelTopBarContext }
