import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import * as HOC from "HOC"
import { PagesRouting } from "./PagesRouting"
import { staticPagesList } from "store"
import { useTranslation } from "react-i18next"
import { Alert } from "reactstrap"

const DataShower = HOC.withDataShower()

const PagesIndexComponent = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(staticPagesList.getList())
    return () => {
      dispatch(staticPagesList.cleanState())
    }
  }, [])

  return (
    <DataShower
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
      customLoaderClass={"page-content"}
      errorContainerClass={"page-content"}
    >
      {props.isFetched && !!props.pages.length ? (
        <PagesRouting pages={props.pages} />
      ) : (
        <div className={"page-content"}>
          <Alert color="secondary">{t("no-pages-created")}</Alert>
        </div>
      )}
    </DataShower>
  )
}

PagesIndexComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  isFetched: PropTypes.bool,
  pages: PropTypes.array,
}

const mapStateToProps = state => {
  let {
    listLoading,
    listError,
    isFetched,
    list: pages,
  } = state.staticPages.list
  return {
    loading: listLoading,
    error: listError,
    isFetched,
    pages,
  }
}

export const PagesIndex = connect(mapStateToProps)(PagesIndexComponent)
