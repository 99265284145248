import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { IconButton, ConfirmPopup } from "components"
import { useConfirmModal } from "hooks"
import { deactivateCompany, selectDeactivateCompany } from "store"
import { useTranslation } from "react-i18next"

export const DeactivateCompany = ({ userId }) => {
  const { t } = useTranslation()

  const { loading } = useSelector(state => selectDeactivateCompany(state))
  const dispatch = useDispatch()

  const { showModal, handleConfirm, modalRef, closeModal } = useConfirmModal(
    () => {
      dispatch(
        deactivateCompany.deactivate({
          id: userId,
        })
      )
    },
    () => {
      dispatch(deactivateCompany.cleanState())
    }
  )

  return (
    <>
      <div className={"d-flex justify-content-end"}>
        <IconButton name={"remove"} bg onClick={showModal} disabled={loading} />
      </div>
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      >
        <p className={"font-size-22"}>{t("deactivate-company-question")}</p>
        <p className={"font-size-14"}>
          {t("deactivate-company-question-hint")}
        </p>
      </ConfirmPopup>
    </>
  )
}

DeactivateCompany.propTypes = {
  userId: PropTypes.any.isRequired,
}
