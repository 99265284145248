import { END, eventChannel } from "redux-saga"

export function createProgressChannel({ request }) {
  let emit
  const channel = eventChannel(emitter => {
    emit = emitter
    return () => {}
  })
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total)
    emit(percentage)
    if (percentage === 100) emit(END)
  }
  const uploadPromise = request(uploadProgressCb)
  return [uploadPromise, channel]
}
