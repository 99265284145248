import React from "react"
import "toastr/build/toastr.min.css"

// initialize api service
// eslint-disable-next-line

// Import scss
import "./assets/scss/theme.scss"

import { RootNavigator } from "./navigation"
import { useAppWindowFocus } from "hooks"

const App = props => {
  useAppWindowFocus()

  return (
    <React.Fragment>
      <RootNavigator />
    </React.Fragment>
  )
}

export default App
