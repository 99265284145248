import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "../../components"
import { LoginForm } from "./components"

export const Login = () => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <MegaTagTitle title={"title.login"} />

      <Card className="overflow-hidden">
        <div className="bg-primary">
          <Row>
            <Col>
              <div className="text-white p-4 text-center">
                <h5 className="text-white mb-0">{t("title.login")}</h5>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody>
          <div className="p-2">
            <LoginForm />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
