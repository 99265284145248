import * as Yup from "yup"

import { EditorUtils } from "utils"

export const validationSchema = Yup.object().shape({
  content: Yup.mixed().test(
    "draft-editor-required",
    "field.error.required",
    value => !EditorUtils.draftJs.isContentEmpty(value)
  ),
})
