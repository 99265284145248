import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { multipleUpload } from "./slice"
import { uploadFileSaga } from "./uploadFileSaga"

export function* uploadFiles({ payload: { filesType, filesList, onSuccess } }) {
  //Call in parallel files uploading
  yield all(
    filesList.map(filePayload => {
      return call(uploadFileSaga, { payload: filePayload, filesType })
    })
  )
  //Finish all files uploading
  yield put(multipleUpload.addFilesCompleted())
  if (onSuccess) {
    onSuccess()
  }
}

//Takes action with array of files
export function* watchAddFiles() {
  yield takeEvery(multipleUpload.addFiles, uploadFiles)
}

function* multipleUploadSaga() {
  yield all([fork(watchAddFiles)])
}

export default multipleUploadSaga
