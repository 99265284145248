import React from "react"
import { useTranslation } from "react-i18next"
import { generatePath, useRouteMatch } from "react-router-dom"

import { PanelMenu } from "components"

export const Nav = () => {
  const { t } = useTranslation()

  let { path } = useRouteMatch()

  const getPath = route => generatePath(`${path}/${route}`)

  const menu = [
    {
      key: "terms-and-conditions",
      route: getPath("terms-and-conditions"),
      title: t("terms-and-conditions"),
    },
  ]

  return <PanelMenu items={menu} />
}

Nav.propTypes = {}
