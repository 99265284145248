import * as Yup from "yup"
import { langHelper } from "helpers/lang"

const langs = langHelper.general.getLanguages()

const valueValidation = langs.reduce((acc, cur) => {
  const { code } = cur

  return (
    (acc[code] = Yup.string().nullable().required("field.error.required")), acc
  )
}, {})

export const validationSchema = Yup.object().shape({
  key: Yup.string().nullable().required("field.error.required"),
  value: Yup.object().shape({
    ...valueValidation,
  }),
})
