import {
  BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY,
  BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY,
} from "consts"
import i18n from "../../i18n"

export const getConfig = value => {
  let data = {
    label: "",
    value: "",
  }
  switch (value) {
    case BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY:
      data = {
        label: i18n.t("billing_subscription.tariff-period.monthly"),
        value: BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY,
      }
      break

    case BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY:
      data = {
        label: i18n.t("billing_subscription.tariff-period.annually"),
        value: BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY,
      }
      break

    default:
      data = { ...data }
      break
  }
  return data
}

export default {
  getConfig,
}
