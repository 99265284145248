import { API } from "../../api"

export const getCountries = async params => {
  return API.common.getCountries(params)
}

export const getMeasurementsOptions = async params => {
  return API.common.getMeasurementsOptions(params)
}

export const getAgencies = async params => {
  return API.agency.getAgencies(params)
}

export const getClients = async params => {
  return API.client.getClients(params)
}

export const getClientCategories = async () => {
  return API.client.getClientCategories()
}

export const getBookers = async params => {
  return API.users.getUsersList(params)
}

export const getDepartments = async params => {
  return API.common.getDepartments(params)
}
