import _ from "lodash"
import it from "assets/images/langs/it.jpg"
import uk from "assets/images/langs/uk.jpg"

export const getLanguages = () => {
  return [
    {
      code: "it",
      title: "Italian",
      flag: it,
    },
    {
      code: "en",
      title: "English",
      flag: uk,
    },
  ]
}

export const getLang = code => {
  const options = getLanguages()
  return _.find(options, { code })
}
