import React from "react"

import { useEchoContext } from "contexts"

export const useEcho = () => {
  const { client, init, setInit } = useEchoContext()

  const create = () => {
    client.create()
    setInit(true)
  }

  const destroy = () => {
    client.destroy()
    setInit(false)
  }

  const privateChannelAddListener = (channel, event, action) => {
    client.privateChannelAddListener(channel, event, action)
  }

  const privateChannelNotification = (channel, action) => {
    client.privateChannelNotification(channel, action)
  }

  const privateChannelAddListeners = (channel, events) => {
    client.privateChannelAddListeners(channel, events)
  }

  const channelLeaveAll = () => {
    client.channelLeaveAll()
  }

  const privateChannelStopListeners = (channel, events) => {
    client.privateChannelStopListeners(channel, events)
  }

  return {
    init,
    create,
    destroy,
    channelLeaveAll,
    privateChannelAddListener,
    privateChannelAddListeners,
    privateChannelNotification,
    privateChannelStopListeners,
  }
}
