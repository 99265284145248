import axios from "axios"
import { RequestUtils } from "utils"

class ChatAPI {
  // Get all user chats
  getChats = async () => {
    return axios.get("api/chats")
  }

  // Get messages from user
  getUserMessages = async (id, params) => {
    return axios.get(`api/chats/users/${id}/messages`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  // Send message to user
  sendMessageToUser = async (id, params) => {
    return axios.post(`api/chats/users/${id}/messages`, params)
  }

  // Make seen massages in chat with user
  seenUserMessages = async id => {
    return axios.post(`api/chats/users/${id}/messages/seen`)
  }

  // Upload file for chat message
  uploadAttachment = async params => {
    let formData = new FormData()
    formData.append("file", params.file)

    return axios.post(`api/chats/files`, formData)
  }

  // // Remove uploaded file
  // deleteAttachment = async id => {
  //   return axios.delete(`api/chats/files/${id}`)
  // }

  // Update chat
  editChat = async (id, params) => {
    return axios.put(`api/chats/${id}`, params)
  }

  // Delete chat
  deleteChat = async id => {
    return axios.delete(`api/chats/${id}`)
  }

  // Create group
  createGroup = async params => {
    return axios.post(`api/chats/groups`, params)
  }

  // Add user to group
  addUserToGroup = async (chat, user) => {
    return axios.post(`api/chats/groups/${chat}/users/${user}`)
  }

  // Delete user from group
  deleteUserFromGroup = async (chat, user) => {
    return axios.delete(`api/chats/groups/${chat}/users/${user}`)
  }

  // Get group messages
  getGroupMessages = async (id, params) => {
    return axios.get(`api/chats/groups/${id}/messages`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  // Send message to group
  sendMessageToGroup = async (id, params) => {
    return axios.post(`api/chats/groups/${id}/messages`, params)
  }

  // Seen group messages
  seenGroupMessages = async id => {
    return axios.post(`api/chats/groups/${id}/messages/seen`)
  }

  // Leave group
  leaveGroup = async id => {
    return axios.post(`api/chats/groups/${id}/leave`)
  }
}

export const chat = new ChatAPI()
