import React, { useReducer } from "react"
import { API } from "api"

const initialState = {
  loading: false,
  shareLink: null,
  error: null,
}

function reducer(state, action) {
  switch (action.type) {
    case "getStarted":
      return {
        ...state,
        loading: true,
      }
    case "getCompleted":
      return {
        ...state,
        loading: false,
        shareLink: action.payload,
      }
    case "getRejected":
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case "setShareLink": {
      return {
        ...state,
        shareLink: action.payload,
      }
    }
    case "cleanState":
      return initialState
    default:
      return state
  }
}

export const useFetchShareLink = () => {
  const [{ loading, shareLink, error }, dispatch] = useReducer(
    reducer,
    initialState
  )

  const fetchLink = async params => {
    dispatch({ type: "getStarted" })
    try {
      const response = await API.share.getShareLink(params)
      const { data } = response
      if (data.sharing_url) {
        dispatch({ type: "getCompleted", payload: data.sharing_url })
      }
      if (data.error) {
        dispatch({ type: "getRejected", payload: data.error })
      }
    } catch (e) {
      dispatch({ type: "getRejected", payload: e.message })
    }
  }

  const setShareLink = link => {
    dispatch({ type: "setShareLink", payload: link })
  }

  const cleanState = () => {
    dispatch({ type: "cleanState" })
  }

  return { loading, shareLink, error, fetchLink, cleanState, setShareLink }
}
