import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const activateCompanySlice = createSlice({
  name: "activateCompany",
  initialState,
  reducers: {
    activate(state) {
      state.loading = true
      state.error = null
    },
    activateCompleted(state) {
      state.loading = false
    },
    activateRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} activate
 *  @property {function} activateCompleted
 *  @property {function} activateRejected
 *  @property {function} cleanState
 */
export const activateCompany = activateCompanySlice.actions

export const selectActivateCompany = state => state.userAccounts.activate

export default activateCompanySlice.reducer
