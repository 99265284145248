import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import removeSaga from "./remove/saga"

function* userIdeasSaga() {
  yield all([listSaga(), removeSaga()])
}

export default userIdeasSaga
