import React, { useRef } from "react"
import PropTypes from "prop-types"
import RcDrawer from "rc-drawer"
import "rc-drawer/assets/index.css"
import classNames from "classnames"
import { useForceUpdate } from "hooks"

export const Drawer = ({
  visible,
  title,
  subtitle,
  headerRightAccessory,
  children,
  className,
  wrapperClassName,
  placement,
  drawerProps,
  onClose,
  destroyOnClose = false,
  onDestroy,
  handler = false,
  onHandleClick,
  header = true,
  ...props
}) => {
  const destroyClose = useRef(false)
  const forceUpdate = useForceUpdate()

  const isDestroyOnClose = destroyOnClose && !visible

  const destroy = () => {
    if (!isDestroyOnClose) {
      return
    }
    if (!visible) {
      destroyClose.current = true
      forceUpdate()

      if (onDestroy) {
        onDestroy()
      }
    }
  }

  const afterVisibleChange = open => {
    if (!open) {
      destroy()
    }
  }

  const renderHandler = () => {
    if (handler) {
      return (
        <div className="drawer-handle">
          <i className="drawer-handle-icon" />
        </div>
      )
    }

    return null
  }

  const closeBtn = (
    <button
      type="button"
      className="btn-close"
      data-dismiss="modal"
      aria-label="Close"
      onClick={onClose}
    />
  )

  const renderTitle = () => (
    <div className={"drawer-header__title"}>
      <h3 className={"mb-0"}>{title}</h3>

      {subtitle && <h6 className={"mb-0 mt-1"}>{subtitle}</h6>}
    </div>
  )

  const renderBody = () => {
    if (destroyClose.current && !visible) {
      return null
    }
    destroyClose.current = false

    return (
      <div className={"drawer-wrapper-body"}>
        {header && (
          <div className={"drawer-header"}>
            {props.renderTitle
              ? props.renderTitle()
              : title
              ? renderTitle()
              : null}

            {headerRightAccessory && headerRightAccessory()}

            <div className={"close-wrap"}>{closeBtn}</div>
          </div>
        )}

        <div className={"drawer-body"}>{children}</div>
      </div>
    )
  }

  return (
    <RcDrawer
      open={visible}
      className={classNames("drawer-block", {
        [className]: className,
      })}
      handler={handler || null}
      onHandleClick={onHandleClick}
      onClose={onClose}
      level={null}
      placement={placement}
      wrapperClassName={classNames({
        [wrapperClassName]: wrapperClassName,
      })}
      afterVisibleChange={afterVisibleChange}
      {...drawerProps}
    >
      {renderBody()}
    </RcDrawer>
  )
}

Drawer.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.any,
  subtitle: PropTypes.string,
  headerRightAccessory: PropTypes.any,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.any,
  drawerProps: PropTypes.any,
  onClose: PropTypes.func,
  destroyOnClose: PropTypes.bool,
  onDestroy: PropTypes.func,
  handler: PropTypes.any,
  onHandleClick: PropTypes.func,
  renderTitle: PropTypes.func,
  header: PropTypes.bool,
}
