import _ from "lodash"

import i18n from "../../i18n"

import {
  MEASUREMENT_SYSTEM_METRIC,
  MEASUREMENT_SYSTEM_IMPERIAL,
} from "../../consts"

export const getMeasurementSystems = () => {
  return [
    {
      label: i18n.t("measurement-system.metric"),
      value: MEASUREMENT_SYSTEM_METRIC,
    },
    {
      label: i18n.t("measurement-system.imperial"),
      value: MEASUREMENT_SYSTEM_IMPERIAL,
    },
  ]
}

export const getMeasurementSystem = value => {
  const options = getMeasurementSystems()
  return _.find(options, { value: value })
}
