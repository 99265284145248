import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { useTranslation } from "react-i18next"
import { MegaTagTitle } from "../../components"
import { TranslationsContainer } from "./components/TranslationsContainer"

export const Translations = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"translations"} />

      <div className="page-content">
        <Container fluid>
          <TranslationsContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}
