import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../i18n"

import { API } from "../../../api"
import { profilePasswordUpdate } from "store/profile/password/slice"

function* changePassword({ payload: { params, onSubmitProps } }) {
  const { reset } = onSubmitProps

  try {
    const response = yield call(API.profile.changePassword, params)

    const { data } = response

    yield put(profilePasswordUpdate.updateCompleted())

    toastr.success(i18n.t("profile.password_change.success"))

    reset()
  } catch (error) {
    const errors = error.response?.data?.errors
      ? error.response.data.errors
      : {}
    const message = error.response?.data?.message
      ? error.response.data.message
      : i18n.t("error")

    yield put(
      profilePasswordUpdate.updateRejected({
        errors,
        message,
      })
    )
  }
}

export function* watchChangePassword() {
  yield takeEvery(profilePasswordUpdate.update, changePassword)
}

function* passwordSaga() {
  yield all([fork(watchChangePassword)])
}

export default passwordSaga
