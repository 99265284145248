import { call, fork, put, take } from "redux-saga/effects"
import { FileUtils, SagaUtils, ValidationsUtils } from "utils"
import { FILE_MAX_SIZE, UPLOAD_FILE_TYPE } from "consts"
import i18n from "i18n"
import { multipleUpload } from "./slice"
import { API } from "api"

export function* uploadFileSaga({
  payload: { id, params, fileConfig, onError, onSuccess },
  filesType,
}) {
  try {
    //Throws error if size grater than max size and stop request
    const isNormalSize = yield call(
      ValidationsUtils.fileMaxSize,
      fileConfig,
      FILE_MAX_SIZE
    )

    if (!isNormalSize) {
      throw Error(
        i18n.t("file.maxSize.error", {
          size: FileUtils.formatBytes(FILE_MAX_SIZE),
        })
      )
    }

    ////////////////////
    yield put(multipleUpload.addFileStarted({ id }))

    //Create channel with request that sends progress data
    const [uploadPromise, channel] = yield call(
      SagaUtils.createProgressChannel,
      {
        request: progressHandler =>
          //make a request depending of Files Type
          makeRequest(filesType, params, progressHandler),
      }
    )
    //Handle progress  data from the channel and update progress state
    yield fork(watchUploadProgress, channel, id)

    //Wait for the response from server
    const res = yield call(() => uploadPromise)

    //If need refetch of the list of files after each uploading
    // if (onSuccess) {
    //   onSuccess()
    // }
    yield put(multipleUpload.addFileCompleted({ id }))
    yield put(multipleUpload.removeFile({ id }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || error.message
    yield put(
      multipleUpload.addFileRejected({
        id,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

const makeRequest = (filesType, params, progressHandler) => {
  switch (filesType) {
    case UPLOAD_FILE_TYPE.WEBSITE_ATTACHMENT: {
      return API.website.addCurrentPageAttachments(
        params.alias,
        params,
        progressHandler
      )
    }
    case UPLOAD_FILE_TYPE.MODEL_PHOTO: {
      return API.model.uploadModelPhoto(params.id, params, progressHandler)
    }
  }
}

function* watchUploadProgress(channel, id) {
  while (true) {
    const progress = yield take(channel)
    yield put(
      multipleUpload.updateFile({
        id,
        progress,
      })
    )
  }
}
