import axios from "axios"
import { RequestUtils } from "utils"

class AnalyticsAPI {
  //====================PERMISSIONS===========================//
  setPermissions = async params => {
    return axios.post(`api/analytic/graphs/acl`, params)
  }
  getPermissions = async graphId => {
    return axios.get(`api/analytic/graphs/${graphId}`)
  }
  //====================GRAPHS==============================//
  getGraphs = async () => {
    return axios.get(`api/analytic/graphs`)
  }
  //==================GRAPH DATA =========================//
  getGraphData = async (alias, params) => {
    return axios.get(`api/analytic/${alias}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
}

export const analytics = new AnalyticsAPI()
