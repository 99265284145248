import axios from "axios"

import { RequestUtils } from "utils"

class UsersAPI {
  getUsersList = async params => {
    return axios.get("api/company/users", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  removeUser = async id => {
    return axios.delete(`api/users/${id}`)
  }

  getContacts = async params => {
    return axios.get("api/contacts", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addContact = async params => {
    return axios.post(`api/contacts`, params)
  }

  removeContact = async id => {
    return axios.delete(`api/contacts/${id}`)
  }
}

export const users = new UsersAPI()
