import { all, call, fork, put, select, takeEvery } from "redux-saga/effects"
import i18n from "i18n"
import { API } from "api"
import { staticMeasurementAll } from "../../../static/actions"
import { userSettingsUpdate } from "./slice"
import { userProfileData } from "store/user/actions"

export const userState = state => state.user.profile
export const measurementState = state => state.static.measurement.all

function* updateSettings({ payload: { params, onSuccess, onError } }) {
  try {
    const user = yield select(userState)
    const currentMeasurementSystem = user?.data?.settings?.measurement_system

    const response = yield call(API.profile.updateSettings, params)

    const { data } = response

    yield put(userSettingsUpdate.updateCompleted())

    yield put(userProfileData.updateData({ settings: data.data }))

    // fetch measurement data if system changed
    const measurement = yield select(measurementState)
    if (
      currentMeasurementSystem !== data.data?.measurement_system &&
      measurement?.data
    ) {
      yield put(staticMeasurementAll.getData({}))
    }

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      userSettingsUpdate.updateRejected({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateSettings() {
  yield takeEvery(userSettingsUpdate.update, updateSettings)
}

function* updateSaga() {
  yield all([fork(watchUpdateSettings)])
}

export default updateSaga
