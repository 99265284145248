import { combineReducers } from "redux"

import list from "./list/slice"
import remove from "./remove/slice"

const userIdeasReducer = combineReducers({
  list,
  remove,
})

export default userIdeasReducer
