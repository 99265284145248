import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { UserIdeas } from "./UserIdeas"

export const UserIdeasIndex = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <UserIdeas />
      </Route>
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
