/* eslint react/prop-types: off */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { langHelper } from "helpers/lang"
import { ReactTableView } from "components"
import { Action } from "./Action"

export const TranslationsTable = props => {
  const { t } = useTranslation()

  const langs = langHelper.general.getLanguages().reverse()

  const [columns, setColumns] = useState([])

  const columnConfig = {
    key: {
      accessor: "key",
      id: "key",
      Header: t("key"),
      Cell: ({ value }) => value,
    },
    action: {
      accessor: "action",
      id: "action",
      Header: "",
      Cell: ({ row: { original } }) => (
        <Action
          data={original}
          onEdit={props.onEdit}
          onRemove={props.onRemove}
        />
      ),
    },
  }

  const getValueColumnConfig = ({ code, title }) => ({
    accessor: `value.${code}`,
    id: `value.${code}`,
    Header: title,
    Cell: ({ value }) => value,
  })

  useEffect(() => {
    const config = []

    config.push(columnConfig["key"])

    for (let i = 0; i < langs.length; i++) {
      const column = getValueColumnConfig(langs[i])
      config.push(column)
    }

    config.push(columnConfig["action"])

    setColumns(config)
  }, [])

  return (
    <ReactTableView
      isLoading={props.loading}
      columns={columns}
      data={props.list}
    />
  )
}

TranslationsTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
}
