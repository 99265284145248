import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IconButton } from "components"

export const Action = ({ data, onEdit, onRemove }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={"d-flex gap-3 justify-content-end"}>
        <IconButton name={"edit"} bg onClick={() => onEdit(data)} />

        <IconButton name={"remove"} bg onClick={() => onRemove(data)} />
      </div>
    </>
  )
}

Action.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
}
