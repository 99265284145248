import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import { translationCreate } from "./slice"
import { API } from "api"
import i18n from "i18n"

function* create({ payload: { params, onSuccess, onError } }) {
  try {
    const response = yield call(API.translation.createTranslation, params)

    const { data } = response

    toastr.success(i18n.t("saved_success"))

    if (onSuccess) {
      onSuccess()
    }
    yield put(translationCreate.createCompleted())
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      translationCreate.createRejected({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchCreate() {
  yield takeEvery(translationCreate.create, create)
}

function* createSaga() {
  yield all([fork(watchCreate)])
}

export default createSaga
