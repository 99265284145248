import _ from "lodash"

import icWhatsapp from "../../assets/images/dev/social/icon-whatsapp.svg"
import icTelegram from "../../assets/images/dev/social/icon-telegram.svg"
import icViber from "../../assets/images/dev/social/icon-viber.svg"
import icInstagram from "../../assets/images/dev/social/icon-instagram.svg"
import icFacebook from "../../assets/images/dev/social/icon-facebook.svg"
import icTwitter from "../../assets/images/dev/social/icon-twitter.svg"
import icLinkedin from "../../assets/images/dev/social/icon-linkedin.svg"
import icTiktok from "../../assets/images/dev/social/icon-tiktok.svg"
import icFacebookMessenger from "../../assets/images/dev/social/icon-facebook-messager.svg"
import icYoutube from "../../assets/images/dev/social/icon-youtube.svg"
import { SOCIAL_NETWORK_LIST } from "../../consts"
import { NormalizeUtils } from "../../utils"

export const getConfig = name => {
  let data = {
    icon: "",
    label: "",
    deeplink: null,
  }

  switch (name) {
    case "whatsapp":
      data = {
        icon: icWhatsapp,
        label: "WhatsApp",
        deeplink: "https://wa.me/",
        inputPlaceholder: "field.placeholder.phone-number",
        inputNormalize: NormalizeUtils.onlyNumbers,
      }
      break

    case "telegram":
      data = {
        icon: icTelegram,
        label: "Telegram",
        deeplink: "https://t.me/",
        inputPlaceholder: "field.placeholder.username",
      }
      break

    case "viber":
      data = {
        icon: icViber,
        label: "Viber",
        deeplink: "viber://chat?number=%2B",
        inputPlaceholder: "field.placeholder.phone-number",
        inputNormalize: NormalizeUtils.onlyNumbers,
      }
      break

    case "instagram":
      data = {
        icon: icInstagram,
        label: "Instagram",
        deeplink: "https://www.instagram.com/",
        inputPlaceholder: "field.placeholder.username",
      }
      break

    case "facebook":
      data = {
        icon: icFacebook,
        label: "Facebook",
        deeplink: "https://www.facebook.com/",
        inputPlaceholder: "field.placeholder.username",
      }
      break

    case "twitter":
      data = {
        icon: icTwitter,
        label: "Twitter",
        deeplink: "https://twitter.com/",
        inputPlaceholder: "field.placeholder.username",
      }
      break

    case "linkedin":
      data = {
        icon: icLinkedin,
        label: "LinkedIn",
        deeplink: "https://www.linkedin.com/in/",
        inputPlaceholder: "field.placeholder.profile-url-name",
      }
      break

    case "tiktok":
      data = {
        icon: icTiktok,
        label: "TikTok",
        deeplink: "https://www.tiktok.com/@",
        inputPlaceholder: "field.placeholder.username",
      }
      break

    case "facebook messenger":
      data = {
        icon: icFacebookMessenger,
        label: "Facebook Messenger",
        deeplink: "https://www.messenger.com/",
        inputPlaceholder: "field.placeholder.username",
      }
      break

    case "youtube":
      data = {
        icon: icYoutube,
        label: "YouTube",
        deeplink: null,
        inputPlaceholder: "youtube_link",
      }
      break

    default:
      data = { ...data }
      break
  }
  return data
}

export const formInitialValues = (values, names) => {
  const fields = names || SOCIAL_NETWORK_LIST

  const result = []

  fields.forEach((fieldName, index) => {
    let fieldItem = { value: "", name: fieldName }

    const field = _.find(values, { name: fieldName })

    if (field) {
      fieldItem = field
    }

    result.push(fieldItem)
  })

  return result
}
