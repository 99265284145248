import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  windowFocus: true,
}

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    windowFocus(state, action) {
      state.windowFocus = action.payload
    },
  },
})

/**
 * @namespace
 * @property {function} windowFocus
 */

export const appState = appStateSlice.actions

export default appStateSlice.reducer
