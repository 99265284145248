import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import i18n from "../../../i18n"
import { userAccountsList } from "./slice"
import { RequestUtils } from "utils"
import { API } from "api"

export function* getList({ payload: { params } }) {
  try {
    let requestParams = {
      includes: [],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(API.admin.getUserAccounts, requestParams)

    const { data } = response
    yield put(userAccountsList.getListCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(userAccountsList.getListRejected(message))
  }
}

export function* watchGetList() {
  yield takeLatest(userAccountsList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
