import { combineReducers } from "redux"

import measurement from "./measurement/reducers"
import country from "./country/reducers"

const staticReducer = combineReducers({
  measurement,
  country,
})

export default staticReducer
