import React from "react"
import { Spinner } from "reactstrap"
import PropTypes from "prop-types"

export const OverlayLoading = ({ isLoading, spinnerSize = "lg" }) => {
  return (
    <>
      {isLoading && (
        <div className={"overlay-block__loader"}>
          <Spinner size={spinnerSize} color="primary" />
        </div>
      )}
    </>
  )
}

OverlayLoading.propTypes = {
  isLoading: PropTypes.bool,
  spinnerSize: PropTypes.string,
}
