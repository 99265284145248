import React, { useCallback, useEffect, useRef, useState } from "react"

/**
 * Represents a useConfirmModal.
 * @constructor
 * @param {*} action - Action
 * @param {*} cleanAction - CleanAction
 * @returns {object}
 */

export const useConfirmModal = (action, cleanAction) => {
  const modalRef = useRef(null)
  const [item, setItem] = useState(null)
  const showModal = useCallback(
    item => {
      item && setItem(item)
      if (modalRef.current) {
        modalRef.current.show()
      }
    },
    [modalRef]
  )
  const closeModal = () => {
    setItem(null)
  }
  const handleConfirm = () => {
    action(item)
  }
  useEffect(() => {
    return () => {
      cleanAction()
    }
  }, [])

  return { modalRef, closeModal, showModal, handleConfirm, item }
}
