import axios from "axios"

import { RequestUtils } from "../../utils"

class TranslationAPI {
  getTranslations = async params => {
    return axios.get(`api/admin/translations`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  createTranslation = async params => {
    return axios.post(`api/admin/translations`, params)
  }

  updateTranslation = async (id, params) => {
    return axios.put(`api/admin/translations/${id}`, params)
  }

  removeTranslation = async id => {
    return axios.delete(`api/admin/translations/${id}`)
  }
}

export const translation = new TranslationAPI()
