import { SET_AUTH_DATA, CLEAN_AUTH_DATA } from "./actionTypes"

export const setAuthData = (isLoggedIn, sessionCode) => {
  return {
    type: SET_AUTH_DATA,
    payload: { isLoggedIn, sessionCode },
  }
}

export const cleanAuthData = () => {
  return {
    type: CLEAN_AUTH_DATA,
  }
}
