import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import SimpleBar from "simplebar-react"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { ROLE_ADMIN } from "consts"

const SidebarContent = props => {
  const ref = useRef()

  const config = [
    {
      title: props.t("title.dashboard"),
      icon: "bx bxs-dashboard",
      route: "/dashboard",
      access: [ROLE_ADMIN],
    },
    {
      title: props.t("title.users"),
      icon: "bx bxs-user-account",
      route: "/users",
      access: [ROLE_ADMIN],
    },
    {
      title: props.t("title.user-ideas"),
      icon: "bx bx-bulb",
      route: "/user-ideas",
      access: [ROLE_ADMIN],
    },
    {
      title: props.t("title.pages"),
      icon: "bx bxs-cog",
      route: "/pages",
      access: [ROLE_ADMIN],
    },
    {
      title: props.t("translations"),
      icon: "mdi mdi-translate",
      route: "/translations",
      access: [ROLE_ADMIN],
    },
  ]

  useEffect(() => {
    const pathName = props.location.pathname

    let ul = document.getElementById("side-menu")
    let items = ul.getElementsByTagName("a")

    const initMenu = () => {
      for (let i = 0; i < items.length; ++i) {
        let el = items[i] // a

        // if current route === link from menu -> set active
        if (pathName === el.pathname) {
          el.classList.add("active")
          let parent = el.parentElement // li

          if (parent) {
            parent.classList.add("mm-active")

            const parent2 = parent.parentElement // ul - "sub-menu"

            if (parent2 && parent2.classList.contains("sub-menu")) {
              // setActive(parent2);
            }
          }
        } else {
          // if current route !== link from menu -> set inactive
          el.classList.remove("active")
          let parent = el.parentElement
          if (parent) {
            parent.classList.remove("mm-active")

            const parent2 = parent.parentElement // ul - "sub-menu"
            if (parent2 && parent2.classList.contains("sub-menu")) {
              // if current link from active submenu -> not inactive submenu
              if (!parent2.classList.contains("sub-menu-show-disabled")) {
                // inactive parent submenu link
                // removeActive(parent2);
              }
            }
          }
        }
      }
    }
    initMenu()
  }, [props.location.pathname])

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  // useEffect(() => {
  //   const pathName = props.location.pathname
  //
  //   const initMenu = () => {
  //     new MetisMenu("#side-menu")
  //     let matchingMenuItem = null
  //     const ul = document.getElementById("side-menu")
  //     const items = ul.getElementsByTagName("a")
  //     for (let i = 0; i < items.length; ++i) {
  //       if (pathName === items[i].pathname) {
  //         matchingMenuItem = items[i]
  //         break
  //       }
  //     }
  //     if (matchingMenuItem) {
  //       activateParentDropdown(matchingMenuItem)
  //     }
  //   }
  //   initMenu()
  // }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const renderSubMenu = submenu => {
    return submenu.map((item, index) => {
      if (item.access.includes(props.user.role)) {
        return (
          <li key={`sidebar-subnav-${index}`}>
            <Link to={item.route}>
              <>
                {item.badge ? item.badge() : null}
                <span>{item.title}</span>
              </>
            </Link>
          </li>
        )
      }
    })
  }

  const renderMenuPoints = () => {
    return config.map((item, index) => {
      if (item.access.includes(props.user.role)) {
        return (
          <li
            key={`sidebar-nav-${index}`}
            className={item.submenu ? " has-arrow" : ""}
          >
            <Link to={item.route} className={``}>
              <i className={`bx ${item.icon}`} />
              <span>{item.title}</span>
              {item.badge ? item.badge() : null}
            </Link>

            {item.submenu && (
              <ul className="sub-menu" aria-expanded="false">
                {renderSubMenu(item.submenu)}
              </ul>
            )}
          </li>
        )
      }
    })
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {renderMenuPoints()}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,

  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.user.profile
  return {
    user: data,
  }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(SidebarContent)))
