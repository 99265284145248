import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import ReactPaginate from "react-paginate"
import PropTypes from "prop-types"
import classNames from "classnames"

export const Pagination = ({ forcePage, data, onPageChange, className }) => {
  const { t } = useTranslation()

  const last_page = data ? data.last_page : 0

  const visible = useMemo(() => {
    return data && data.last_page > 1
  }, [data])

  return (
    <>
      {visible && (
        <ReactPaginate
          previousLabel={t("previous")}
          nextLabel={t("next")}
          breakLabel={"..."}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          pageCount={last_page}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={onPageChange}
          containerClassName={classNames(
            "pagination justify-content-end pagination_theme_primary",
            {
              [className]: className,
            }
          )}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          activeClassName={"active"}
          disabledClassName={"disabled"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          ariaLabelBuilder={() => "page"}
          forcePage={data.current_page - 1}
        />
      )}
    </>
  )
}

Pagination.propTypes = {
  data: PropTypes.object,
  onPageChange: PropTypes.func,
  forcePage: PropTypes.number,
  className: PropTypes.string,
}
