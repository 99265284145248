import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  withRouter,
} from "react-router-dom"

import { LoadingScreen, PanelLayout, SocketMiddleware } from "components"
import { adminRoutes } from "routes/allRoutes"
import { ProtectedRoute } from "routes/middleware"
import { ErrorCard } from "./components"
import { fetchUserData } from "store/actions"
import { ROLE_ADMIN } from "consts"
import { EchoProvider } from "contexts"

const PanelComponent = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch(fetchUserData.fetch({ history }))
    return () => {
      dispatch(fetchUserData.cleanState())
    }
  }, [])

  const renderRoutesByRole = () => {
    let routes = []
    switch (props.user?.role) {
      case ROLE_ADMIN:
        routes = adminRoutes
        break
      default:
        break
    }
    return routes.map((route, idx) => (
      <ProtectedRoute
        {...route}
        path={route.path}
        key={idx}
        middlewares={route.middlewares}
        component={route.component}
      />
    ))
  }

  return (
    <>
      {props.loading ? (
        <LoadingScreen loading={props.loading} />
      ) : props.user && !props.loading ? (
        <EchoProvider>
          <SocketMiddleware>
            <PanelLayout>
              <Switch>
                <Route exact path={"/"}>
                  <Redirect to={`/dashboard`} />
                </Route>

                {renderRoutesByRole()}
              </Switch>
            </PanelLayout>
          </SocketMiddleware>
        </EchoProvider>
      ) : props.error ? (
        <ErrorCard error={props.error} />
      ) : null}
    </>
  )
}

PanelComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.user.load
  const { data } = state.user.profile
  return {
    loading: loading,
    error: error,
    user: data,
  }
}

export const Panel = withRouter(connect(mapStateToProps)(PanelComponent))
