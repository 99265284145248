import { ContentState, convertToRaw, EditorState } from "draft-js"
import htmlToDraft from "html-to-draftjs"
import draftToHtml from "draftjs-to-html"

const getEditorState = html => {
  const { contentBlocks, entityMap } = htmlToDraft(html || "")
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  )
  return EditorState.createWithContent(contentState)
}

const editorStateToHtml = editorState => {
  const rawContentState = convertToRaw(editorState.getCurrentContent())
  return draftToHtml(rawContentState)
}

const isContentEmpty = editorState => {
  if (
    convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
    convertToRaw(editorState.getCurrentContent()).blocks[0].text === ""
  ) {
    return true
  }
  return false
}

export { getEditorState, editorStateToHtml, isContentEmpty }
