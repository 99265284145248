import { all, fork } from "redux-saga/effects"

import LayoutSaga from "./layout/saga"
import authSagas from "./auth/sagas"
import userSagas from "./user/sagas"
import profileSagas from "./profile/sagas"
import staticSagas from "./static/sagas"
import multipleUploadSagas from "./multipleUpload/saga"
import socketSagas from "./socket/sagas"
import userIdeasSagas from "./userIdeas/sagas"
import userAccountsSagas from "./userAccounts/sagas"
import staticPagesSagas from "./staticPages/sagas"
import translationSaga from "./translation/sagas"

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(authSagas),
    fork(userSagas),
    fork(profileSagas),
    fork(staticSagas),
    fork(multipleUploadSagas),
    fork(socketSagas),
    fork(userIdeasSagas),
    fork(userAccountsSagas),
    fork(staticPagesSagas),
    fork(translationSaga),
  ])
}
