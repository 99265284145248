import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import {
  Button,
  ControllerHF,
  InputField,
  PasswordInputFiled,
} from "components"
import { loginCleanState, loginSendVerifyEmail, loginUser } from "store/actions"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

const passwordMinLength = 8

const LoginFormComponent = props => {
  const { t } = useTranslation()
  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email("field.error.invalid")
          .required("field.error.required"),
        password: Yup.string()
          .min(passwordMinLength, "field.error.password.length")
          .required("field.error.required"),
      })
    ),
    mode: "onChange",
  })

  useEffect(() => {
    return () => {
      props.loginCleanState()
    }
  }, [])

  const onSubmit = values => {
    props.loginUser(values, { reset, setError })
  }

  return (
    <>
      {props.loginError && (
        <Alert color="danger">{props.loginError.message}</Alert>
      )}

      <form className={"form-horizontal"} onSubmit={handleSubmit(onSubmit)}>
        <ControllerHF
          name={"email"}
          label={t("field.label.email")}
          placeholder={t("field.placeholder.email")}
          control={control}
          component={InputField}
          id={"town"}
          type="email"
        />
        <ControllerHF
          name="password"
          label={t("field.label.password")}
          placeholder={t("field.placeholder.password")}
          control={control}
          component={PasswordInputFiled}
          id={"password"}
          translateParams={{ count: passwordMinLength }}
        />
        <div className="mt-3 d-grid">
          <Button
            submit
            title={"btn.login"}
            disabled={props.loading}
            loading={props.loading}
          />
        </div>
      </form>
    </>
  )
}

LoginFormComponent.propTypes = {
  loginError: PropTypes.object,
  loading: PropTypes.bool,

  loginCleanState: PropTypes.func,
  loginUser: PropTypes.func,
  loginSendVerifyEmail: PropTypes.func,
}

const mapStateToProps = state => {
  const { loginError, loading } = state.auth.login
  return {
    loginError,
    loading,
  }
}

const mapDispatchToProps = {
  loginUser,
  loginCleanState,
  loginSendVerifyEmail,
}

export const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormComponent)
