import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const removeStaticPageSlice = createSlice({
  name: "removeStaticPage",
  initialState,
  reducers: {
    remove(state) {
      state.loading = true
      state.error = null
    },
    removeCompleted(state) {
      state.loading = false
    },
    removeRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} remove
 *  @property {function} removeCompleted
 *  @property {function} removeRejected
 *  @property {function} cleanState
 */

export const removeStaticPage = removeStaticPageSlice.actions

export default removeStaticPageSlice.reducer
