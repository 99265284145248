import axios from "axios"

class ShareAPI {
  getShareLink = async params => {
    return axios.get("api/share", {
      params,
    })
  }
}

export const share = new ShareAPI()
