import axios from "axios"
import { RequestUtils } from "../../utils"

class ClientAPI {
  //====================GENERAL===========================//
  getClientCategories = async () => {
    return axios.get("api/client-categories")
  }
  getClients = async params => {
    return axios.get("api/clients", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  getClient = async id => {
    return axios.get(`api/clients/${id}`)
  }

  addClient = async params => {
    let formData = new FormData()

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })
    return axios.post("api/clients", formData)
  }

  updateClient = async (id, params) => {
    return axios.put(`api/clients/${id}`, params)
  }

  removeClient = async id => {
    return axios.delete(`api/clients/${id}`)
  }
  //===================AVATAR==========================//
  addClientAvatar = async (id, params) => {
    let formData = new FormData()
    formData.append("avatar", params.avatar)
    return axios.post(`api/clients/${id}/avatar`, formData)
  }

  removeClientAvatar = async id => {
    return axios.delete(`api/clients/${id}/avatar`)
  }

  //===================HISTORY======================//
  getClientHistory = async id => {
    return axios.get(`/api/clients/${id}/histories`)
  }

  //=====================RATING=============================//
  getClientRatingsData = async (id, params) => {
    return axios.get(`api/clients/${id}/ratings`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addClientRating = async (id, params) => {
    return axios.post(`api/clients/${id}/ratings`, params)
  }

  removeClientRating = async id => {
    return axios.delete(`api/clients/ratings/${id}`)
  }

  //====================FILE=========================//
  getClientDocuments = async (id, params) => {
    return axios.get(`api/clients/${id}/files`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  addClientDocument = async (id, params) => {
    let formData = new FormData()

    formData.append("file", params.file)
    formData.append("title", params.title)
    return axios.post(`api/clients/${id}/files`, formData)
  }
  updateClientDocument = async (id, params) => {
    let formData = new FormData()
    formData.append("_method", "PUT")
    formData.append("file", params.file)
    formData.append("title", params.title)

    return axios.post(`api/clients/files/${id}`, formData)
  }
  removeClientDocument = async id => {
    return axios.delete(`api/clients/files/${id}`)
  }
  //===================Address=======================//
  getClientAddresses = async (id, params) => {
    return axios.get(`api/clients/${id}/addresses`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  addClientAddress = async (id, params) => {
    return axios.post(`api/clients/${id}/addresses`, params)
  }
  getClientAddress = async (id, params) => {
    return axios.get(`api/clients/addresses/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  updateClientAddress = async (id, params) => {
    return axios.put(`api/clients/addresses/${id}`, params)
  }
  removeClientAddress = async id => {
    return axios.delete(`api/clients/addresses/${id}`)
  }

  //===================CONTACT=======================//
  getClientContacts = async (id, params) => {
    return axios.get(`api/clients/${id}/contacts`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  addClientContact = async (id, params) => {
    return axios.post(`api/clients/${id}/contacts`, params)
  }
  getClientContact = async (id, params) => {
    return axios.get(`api/clients/contacts/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  updateClientContact = async (id, params) => {
    return axios.put(`api/clients/contacts/${id}`, params)
  }
  removeClientContact = async id => {
    return axios.delete(`api/clients/contacts/${id}`)
  }
  uploadFileForNewContact = async params => {
    let formData = new FormData()
    formData.append("file", params.file)
    return axios.post(`api/clients/contacts/files`, formData)
  }
  uploadFileForContact = async (contactId, params) => {
    let formData = new FormData()

    formData.append("file", params.file)

    return axios.post(`api/clients/contacts/${contactId}/files`, formData)
  }
  removeClientContactFile = async id => {
    return axios.delete(`api/clients/contacts/files/${id}`)
  }

  //=======================PRICES===============================//
  getClientPrices = async (id, params) => {
    return axios.get(`api/clients/${id}/prices`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  addClientPrice = async (id, params) => {
    return axios.post(`api/clients/${id}/prices`, params)
  }
  getClientPrice = async (id, params) => {
    return axios.get(`api/clients/prices/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  updateClientPrice = async (id, params) => {
    return axios.put(`api/clients/prices/${id}`, params)
  }
  removeClientPrice = async id => {
    return axios.delete(`api/clients/prices/${id}`)
  }

  // Client statements
  // get list
  getClientStatements = async (id, params) => {
    return axios.get(`api/clients/${id}/statements`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  // get
  getClientStatement = async (id, params) => {
    return axios.get(`api/clients/statements/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  // update
  updateClientStatement = async (id, params) => {
    return axios.put(`api/clients/statements/${id}`, params)
  }
  // --- //
}

export const client = new ClientAPI()
