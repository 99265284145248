import React from "react"
import PropTypes from "prop-types"

export const BlankLayout = props => {
  return <React.Fragment>{props.children}</React.Fragment>
}

BlankLayout.propTypes = {
  children: PropTypes.object,
}
