import React from "react"
import PropTypes from "prop-types"
import { PagesLayout } from "./components"
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom"
import { TermsAndConditions } from "./TermsAndConditions"
import { pagesHelper } from "helpers"
import { TERMS_AND_CONDITIONS } from "consts"

const { hasPage } = pagesHelper.common

export const PagesRouting = props => {
  const { path } = useRouteMatch()

  let renderPageRoute = pageAlias => {
    if (!hasPage(props.pages, pageAlias)) {
      return null
    }
    switch (pageAlias) {
      case TERMS_AND_CONDITIONS: {
        return (
          <Route key={pageAlias} path={`${path}/terms-and-conditions`}>
            <TermsAndConditions />
          </Route>
        )
      }
    }
  }

  return (
    <PagesLayout>
      <Switch>
        <Route exact path={path}>
          <Redirect to={generatePath(`${path}/terms-and-conditions`)} />
        </Route>
        {[TERMS_AND_CONDITIONS].map(pageAlias => {
          return renderPageRoute(pageAlias)
        })}
        <Route>
          <Redirect to={generatePath(`${path}/terms-and-conditions`)} />
        </Route>
      </Switch>
    </PagesLayout>
  )
}

PagesRouting.propTypes = {
  pages: PropTypes.array,
}
