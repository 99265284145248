import axios from "axios"

import { RequestUtils } from "../../utils"

class ModelsGroupsAPI {
  getGroups = async params => {
    return axios.get(`api/groups`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  getGroup = async id => {
    return axios.get(`api/groups/${id}`)
  }
  addGroup = async params => {
    return axios.post(`api/groups`, params)
  }

  updateGroup = async (id, params) => {
    return axios.put(`api/groups/${id}`, params)
  }

  removeGroup = async id => {
    return axios.delete(`api/groups/${id}`)
  }
}

export const modelsGroups = new ModelsGroupsAPI()
