import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Input, Label } from "reactstrap"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { useDebounce } from "../../../hooks"

export const SearchInput = ({
  id = "search",
  name = "search",
  label,
  placeholder,
  normalize,
  disabled,
  value,
  onInputChange,
  onSearch,
  className,
  delay = 1000,
  disableInitialCallback = true,
  minLimit = 1,
  autoComplete = "on",
}) => {
  const { t } = useTranslation()

  const debouncedValue = useDebounce(value, delay)

  const disableInitialStatusRef = useRef(disableInitialCallback)

  useEffect(() => {
    if (!disableInitialStatusRef.current) {
      let searchValue = debouncedValue.trim()

      if (searchValue.length < minLimit) {
        searchValue = null
      }

      onSearch(searchValue)
    }

    disableInitialStatusRef.current = false
  }, [debouncedValue])

  const handleChange = e => {
    onInputChange(e.target.value)
  }

  const leftAccessory = () => (
    <div className={"form-left-accessory"}>
      <i className={"mdi mdi-magnify font-size-16"} />
    </div>
  )

  return (
    <div
      className={classNames({
        [className]: className,
      })}
    >
      {label && <Label htmlFor={id}>{label}</Label>}
      <div className={classNames("input-group")}>
        {leftAccessory()}

        <Input
          type={"text"}
          className="form-control form-control-left-accessory form-control-border-left-round"
          id={id}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={event => handleChange(event)}
          autoComplete={autoComplete}
        />
      </div>

      {debouncedValue && debouncedValue.length < minLimit && (
        <div className={"search-hint text-danger mt-1"}>
          {t("search.hint.min", { count: minLimit })}
        </div>
      )}
    </div>
  )
}

SearchInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  normalize: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onInputChange: PropTypes.func,
  onSearch: PropTypes.func,
  className: PropTypes.string,
  delay: PropTypes.number,
  disableInitialCallback: PropTypes.bool,
  minLimit: PropTypes.number,
  autoComplete: PropTypes.string,
}
