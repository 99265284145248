import { combineReducers } from "redux"

import load from "./load/slice"
import profile from "./profile/slice"
import settings from "./settings/reducers"

const userReducer = combineReducers({
  load,
  profile,
  settings,
})

export default userReducer
