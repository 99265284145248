import React from "react"
import PropTypes from "prop-types"

export const LoadingScreen = ({ loading }) => {
  return (
    <>
      {loading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

LoadingScreen.propTypes = {
  loading: PropTypes.bool,
}
