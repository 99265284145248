import axios from "axios"

class ExpenseAPI {
  getExpense = async id => {
    return axios.get(`api/expenses/${id}`)
  }

  updateExpense = async (id, params) => {
    return axios.put(`api/expenses/${id}`, params)
  }

  removeExpense = async id => {
    return axios.delete(`api/expenses/${id}`)
  }
}

export const expense = new ExpenseAPI()
