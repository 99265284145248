import React from "react"
import PropTypes from "prop-types"
import { Col, Container, Row } from "reactstrap"
import { Header } from "./Header"
import { Footer } from "./Footer"
import { AuthLogo } from "./AuthLogo"

const AuthLayoutComponent = props => {
  return (
    <>
      <Header />

      <div className="account-pages pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <AuthLogo />
              {props.children}

              <Footer />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

AuthLayoutComponent.propTypes = {
  children: PropTypes.object,
}

export const AuthLayout = AuthLayoutComponent
