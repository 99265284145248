import axios from "axios"

import { RequestUtils } from "utils"

class ProfileAPI {
  getProfile = async params => {
    return axios.get("api/profile", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  editProfile = async params => {
    return axios.put("api/profile", params)
  }

  removeUser = async params => {
    return axios.delete("api/profile", params)
  }

  changePassword = async params => {
    return axios.post("api/profile/password", params)
  }

  updateAvatar = async data => {
    let formData = new FormData()
    formData.append("avatar", data)

    return axios.post("api/profile/avatar", formData)
  }

  removeAvatar = async () => {
    return axios.delete("api/profile/avatar")
  }

  getUser = async (id, params) => {
    return axios.get(`api/users/${id}`, { params })
  }

  getSettings = async params => {
    return axios.get("api/profile/settings", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  updateSettings = async params => {
    return axios.post("api/profile/settings", params)
  }

  getOptions = async () => {
    return axios.get("api/profile/options")
  }

  updateOptions = async params => {
    return axios.put("api/profile/options", params)
  }

  // Company
  getCompany = async () => {
    return axios.get("api/profile/company")
  }

  updateCompany = async params => {
    return axios.put("api/profile/company", params)
  }

  updateCompanyLogo = async data => {
    let formData = new FormData()
    formData.append("avatar", data)

    return axios.post("api/profile/company/avatar", formData)
  }

  removeCompanyLogo = async () => {
    return axios.delete("api/profile/company/avatar")
  }

  restoreCompany = async () => {
    return axios.post("api/profile/company/restore")
  }
  // === //
}

export const profile = new ProfileAPI()
