import axios from "axios"

import { RequestUtils } from "../../utils"

class AgencyAPI {
  //====================GENERAL===========================//
  getAgencies = async params => {
    return axios.get("api/agencies", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  getAgency = async id => {
    return axios.get(`api/agencies/${id}`)
  }

  addAgency = async params => {
    let formData = new FormData()

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })
    return axios.post("api/agencies", formData)
  }

  updateAgency = async (id, params) => {
    return axios.put(`api/agencies/${id}`, params)
  }

  removeAgency = async id => {
    return axios.delete(`api/agencies/${id}`)
  }
  //===================AVATAR==========================//
  addAgencyAvatar = async (id, params) => {
    let formData = new FormData()
    formData.append("avatar", params.avatar)
    return axios.post(`api/agencies/${id}/avatar`, formData)
  }

  removeAgencyAvatar = async id => {
    return axios.delete(`api/agencies/${id}/avatar`)
  }

  //===================HISTORY======================//
  getAgencyHistory = async id => {
    return axios.get(`/api/agencies/${id}/histories`)
  }

  //=====================RATING=============================//
  getAgencyRatingsData = async (id, params) => {
    return axios.get(`api/agencies/${id}/ratings`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addAgencyRating = async (id, params) => {
    return axios.post(`api/agencies/${id}/ratings`, params)
  }

  removeAgencyRating = async id => {
    return axios.delete(`api/agencies/ratings/${id}`)
  }

  //====================FILE=========================//
  getAgencyAttachments = async (id, params) => {
    return axios.get(`api/agencies/${id}/files`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  addAgencyAttachment = async (id, params) => {
    let formData = new FormData()

    formData.append("file", params.file)
    formData.append("title", params.title)
    return axios.post(`api/agencies/${id}/files`, formData)
  }
  updateAgencyAttachment = async (id, params) => {
    let formData = new FormData()
    formData.append("_method", "PUT")
    formData.append("file", params.file)
    formData.append("title", params.title)

    return axios.post(`api/agencies/files/${id}`, formData)
  }
  removeAgencyAttachment = async id => {
    return axios.delete(`api/agencies/files/${id}`)
  }
  //===================Address=======================//
  getAgencyAddresses = async (id, params) => {
    return axios.get(`api/agencies/${id}/addresses`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  addAgencyAddress = async (id, params) => {
    return axios.post(`api/agencies/${id}/addresses`, params)
  }
  getAgencyAddress = async (id, params) => {
    return axios.get(`api/agencies/addresses/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  updateAgencyAddress = async (id, params) => {
    return axios.put(`api/agencies/addresses/${id}`, params)
  }
  removeAgencyAddress = async id => {
    return axios.delete(`api/agencies/addresses/${id}`)
  }

  //===================CONTACT=======================//
  getAgencyContacts = async (id, params) => {
    return axios.get(`api/agencies/${id}/contacts`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  addAgencyContact = async (id, params) => {
    return axios.post(`api/agencies/${id}/contacts`, params)
  }
  getAgencyContact = async (id, params) => {
    return axios.get(`api/agencies/contacts/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  updateAgencyContact = async (id, params) => {
    return axios.put(`api/agencies/contacts/${id}`, params)
  }
  removeAgencyContact = async id => {
    return axios.delete(`api/agencies/contacts/${id}`)
  }
  uploadFileForNewContact = async params => {
    let formData = new FormData()
    formData.append("file", params.file)
    return axios.post(`api/agencies/contacts/files`, formData)
  }
  uploadFileForContact = async (contactId, params) => {
    let formData = new FormData()

    formData.append("file", params.file)

    return axios.post(`api/agencies/contacts/${contactId}/files`, formData)
  }
  removeAgencyContactFile = async id => {
    return axios.delete(`api/agencies/contacts/files/${id}`)
  }
}

export const agency = new AgencyAPI()
