import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGIN_USER_CLEAN_STATE,
  LOGIN_SEND_VERIFY_EMAIL,
} from "./actionTypes"

export const loginUser = (params, onSubmitProps) => {
  return {
    type: LOGIN_USER,
    payload: { params, onSubmitProps },
  }
}

export const loginUserSuccess = data => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: data,
  }
}

export const loginUserError = error => {
  return {
    type: LOGIN_USER_ERROR,
    payload: error,
  }
}

export const loginCleanState = () => {
  return {
    type: LOGIN_USER_CLEAN_STATE,
  }
}

export const loginSendVerifyEmail = params => {
  return {
    type: LOGIN_SEND_VERIFY_EMAIL,
    payload: { params },
  }
}
