import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { SearchInput } from "components"

export const GeneralFilter = ({ onSearch }) => {
  const { t } = useTranslation()

  const [searchInputValue, setSearchInputValue] = useState("")

  const handleInputChange = value => {
    setSearchInputValue(value)
  }
  return (
    <Row>
      <Col sm={6} md={4}>
        <SearchInput
          value={searchInputValue}
          onInputChange={handleInputChange}
          onSearch={onSearch}
          placeholder={t("search")}
          autoComplete={"off"}
          minLimit={3}
        />
      </Col>
    </Row>
  )
}

GeneralFilter.propTypes = {
  onSearch: PropTypes.func,
}
