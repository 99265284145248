import React from "react"
import PropTypes from "prop-types"
import { Alert, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { Button, InputField, ControllerHF } from "components"

export const UserGenerateForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      email: ""
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email("field.error.invalid")
          .required("field.error.required")
      })
    )
  })

  const onSubmit = values => {
    const params = {
      ...values
    }

    props.onSubmit(params, { reset, setError })
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <ControllerHF
              name={"email"}
              control={control}
              component={InputField}
              id={"email"}
              label={t("field.label.email")}
              placeholder={t("field.placeholder.email")}
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"add"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </>
  )
}

UserGenerateForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}
