import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle, PageHeader } from "../../components"

const DashboardComponent = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.dashboard"} />

      <div className="page-content">
        <Container fluid>
          <PageHeader title={t("title.dashboard")} />
        </Container>
      </div>
    </React.Fragment>
  )
}

DashboardComponent.propTypes = {
  user: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    user: state.user.profile.data,
  }
}

const mapDispatchToProps = {}

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardComponent)
