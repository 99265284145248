import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { FormFeedback } from "reactstrap"
import classNames from "classnames"

export const FieldError = ({
  showError,
  error,
  translateParams,
  className,
}) => {
  const { t } = useTranslation()

  const getMessage = () => {
    if (typeof error === "string") {
      return error
    }
    if (error?.message) {
      return error.message
    }
    return ""
  }

  return (
    <FormFeedback
      invalid={showError ? error : null}
      className={classNames({ [className]: className })}
    >
      {t(getMessage(), translateParams ? { ...translateParams } : {})}
    </FormFeedback>
  )
}

FieldError.propTypes = {
  showError: PropTypes.bool,
  error: PropTypes.any,
  translateParams: PropTypes.object,
  className: PropTypes.string,
}
