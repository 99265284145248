import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import * as HOC from "../../HOC"
import { Pagination } from "../Pagination"

const DataShower = HOC.withDataShowerOverlay()

export const GridDataLoader = ({
  loading,
  error,
  items = [],
  renderItem,
  meta,
  onPageChange,
  empty = "empty",
  className = "",
}) => {
  const { t } = useTranslation()

  return (
    <DataShower isLoading={loading} isFailed={error} error={error}>
      <div className={className}>
        {!!items.length ? (
          <>
            <div className="grid">
              <div className="grid__row">
                {items.map((item, i) => (
                  <div className="grid__col" key={`grid-item-${item.id}`}>
                    <div className="grid__item">{renderItem(item)}</div>
                  </div>
                ))}
              </div>
            </div>

            <Pagination data={meta} onPageChange={onPageChange} />
          </>
        ) : !loading ? (
          t(empty)
        ) : null}
      </div>
    </DataShower>
  )
}

GridDataLoader.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  renderItem: PropTypes.func,
  empty: PropTypes.any,
  onPageChange: PropTypes.func,
  className: PropTypes.string,
}
