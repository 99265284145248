import React from "react"
import { Col, Row } from "reactstrap"
import { MegaTagTitle } from "../../components"
import { TermsAndConditionsContainer } from "./components"

export const TermsAndConditions = () => {
  return (
    <div className={"pages-terms-conditions"}>
      <MegaTagTitle title={"title.terms-and-conditions"} />
      <Row>
        <Col>
          <TermsAndConditionsContainer />
        </Col>
      </Row>
    </div>
  )
}
