import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"
import { Button, ControllerHF, EditorField } from "components"
import { EditorUtils } from "utils"
import { EDITOR_DRAFT_COMMON_TOOLBAR_OPTIONS } from "consts"
import { validationSchema } from "./validationSchema"

export const TermsAndConditionsForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      content: EditorUtils.draftJs.getEditorState(""),
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  })

  const onSubmit = values => {
    const { content } = values
    let params = {}
    if (content && !EditorUtils.draftJs.isContentEmpty(content)) {
      params.content = EditorUtils.draftJs.editorStateToHtml(content)
    } else {
      params.content = null
    }

    props.onSubmit(params, { reset, setError })
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <ControllerHF
              name={"content"}
              control={control}
              component={EditorField}
              id={"content"}
              label={t("page-content")}
              placeholder={t("page-content")}
              toolbar={{
                options: EDITOR_DRAFT_COMMON_TOOLBAR_OPTIONS,
              }}
            />
          </Col>
        </Row>
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />
          </div>
        </div>
      </form>
    </>
  )
}

TermsAndConditionsForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
}
