import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_USER_LOGIN = "socket/onUserLogin"

export const socketOnUserLogin = createAction(
  SOCKET_ON_USER_LOGIN,
  function prepare({ data, history }) {
    return {
      payload: {
        data,
        history,
      },
    }
  }
)
