import React, { useRef, useState } from "react"

/**
 * Represents a useModal.
 * @constructor
 * @param {*} onCloseAction - on close action
 * @returns {object}
 */

export const useModal = onCloseAction => {
  const modalRef = useRef(null)
  const [item, setItem] = useState(null)
  const showModal = item => {
    if (modalRef.current) {
      modalRef.current.show()
    }
    item && setItem(item)
  }

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const onModalClose = () => {
    if (onCloseAction) {
      onCloseAction(item)
    }
    item && setItem(null)
  }

  return { modalRef, showModal, closeModal, item, onModalClose }
}
